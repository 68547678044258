import axios from "axios";

// const USER_API_BASE_URL =
//   "https://cors-anywhere.herokuapp.com/https://gatewayapi.invest19.com/";

// const CMP_API =
//   "https://cors-anywhere.herokuapp.com/https://gatewayapi.invest19.com/watchlist/marketdepth/scrip/marketprice";

// live = "https://gatewayapi.invest19.com/";
// live2 = "http://in19gatewayapilive1-env.eba-za5pk2wj.ap-south-1.elasticbeanstalk.com/";

// const IPO_API_BASE_URL = "https://cors-anywhere.herokuapp.com/https://ipomaster.invest19.com/api/"; 
 // ipo base url


const USER_API_BASE_URL = "https://gatewayapi.invest19.com/";

const IPO_API_BASE_URL = "https://gatewayapi.invest19.com/";

 
const CMP_API =
  "https://gatewayapi.invest19.com/watchlist/marketdepth/scrip/marketprice";
  
const ENDPOINT_CATEGORY = "research/find/all/active/nonactive/scrips";
const SAVE_SCRIP = "research/save/scrip";
const SEARCH_SCRIP = "research/find/scrip/by/symbol/or/name";
const SELECT_CATEGORY_LIST = "research/find/scrip/categories";
const LOGIN_END_POINT = "user/auth/research/login";
const MODIFY_SCRIP = "research/modify/scrip";

// ipo end point
const IPO_LIST = "api/get/all?platform=web&filter=Upcoming,Ongoing,Closed&page=0&size=10000000";
const MODIFY_IPO = "api/edit/ipo";


// holiday
const HOLIDAY_LIST = "super/get/holiday/list?page_from=0&page_to=10000000";
const MODIFY_HOLIDAY = "super/edit/exchange/holiday";


// settlement end point
const SETTLEMENT_LIST = "edis/get/settlement/master/list?page_from=0&page_to=100";
const MODIFY_SETTLEMENT = "edis/edit/settlement/detalis";






class ApiService {
  fetchAllData() {
    return axios.get(USER_API_BASE_URL + ENDPOINT_CATEGORY);
  }

  researchLogin(values) {
    return axios.post(
      `${USER_API_BASE_URL}${LOGIN_END_POINT}?adminId=${values.userId}&adminPassword=${values.password}`
    );
  }

  modifyScripData(data) {
    return axios.put(USER_API_BASE_URL + MODIFY_SCRIP, data);
  }
  closeScripData(data) {
    return axios.put(USER_API_BASE_URL + MODIFY_SCRIP, data);
  }

  publishScripData(data) {
    return axios.put(USER_API_BASE_URL + MODIFY_SCRIP, data);
  }
  getScripData() {
    return axios.post(USER_API_BASE_URL + ENDPOINT_CATEGORY);
  }
  saveScripData(saveScrip) {
    return axios.post(USER_API_BASE_URL + SAVE_SCRIP, saveScrip);
  }

  searchScripDataList(searchScrip) {
    return axios.post(USER_API_BASE_URL + SEARCH_SCRIP, searchScrip);
  }
  categoryDataList(categoryList) {
    return axios.post(USER_API_BASE_URL + SELECT_CATEGORY_LIST, categoryList);
  }

  cmpData(cmpBodyData) {
    return axios.post(CMP_API, cmpBodyData);
  }


// ipo API


fetchAllDataIPO() {
  return axios.get(IPO_API_BASE_URL + IPO_LIST);
}

modifyIPOData(data) {
  return axios.post(USER_API_BASE_URL + MODIFY_IPO, data);
}



// Holiday API
fetchAllDataHoliday() {
  return axios.get(IPO_API_BASE_URL + HOLIDAY_LIST);
}

modifyHolidayData(data) {
  return axios.post(USER_API_BASE_URL + MODIFY_HOLIDAY, data);
}
// Holiday API




// settlement
fetchAllDataIPOSettlement() {
  return axios.get(USER_API_BASE_URL + SETTLEMENT_LIST);
}

modifyIPODataSettlement(data) {
  return axios.post(USER_API_BASE_URL + MODIFY_SETTLEMENT, data);
}

// modifyIPOData(data: any) {
//   return axios.post(IPO_API_BASE_URL + MODIFY_IPO, data);
// }


}

export default new ApiService();
