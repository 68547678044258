import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import IconDashboard from "@material-ui/icons/Dashboard";
import AppMenuItem from "./AppMenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const AppMenu: React.FC = () => {
  const classes = useStyles();
  let appMenuItems;
  let userMetaInfo: any = localStorage.getItem("data");
  let updatedMetaInfo = JSON.parse(userMetaInfo);
  if (updatedMetaInfo !== null && updatedMetaInfo.adminRollType === "U") {
    appMenuItems = [
      {
        name: "Research Call",
        link: "/",
        Icon: IconDashboard,
      },
      {
        name: "IPO List",
        link: "/Ipo-add",
        Icon: IconDashboard,
      },
      {
        name: "Holiday Calendar",
        link: "/Holiday-Calendar",
        Icon: IconDashboard,
      },
      {
        name: "Settlement List",
        link: "/SettlementLIst",
        Icon: IconDashboard,
      },
      
      {
        name: "Share Link",
        link: "/shareLink",
        Icon: IconDashboard,
      },
      {
        name: "Logout",
        link: "/logout",
        Icon: ExitToAppIcon,
      },
    ];
  } else {
    appMenuItems = [
      {
        name: "Research Call",
        link: "/",
        Icon: IconDashboard,
      },
      {
        name: "IPO",
        link: "/ipo-add",
        Icon: IconDashboard,
      },
      {
        name: "ExistingListTableComponent",
        link: "/ExistingListTableComponent",
        Icon: IconDashboard,
      },
      {
        name: "Logout",
        link: "/logout",
        Icon: ExitToAppIcon,
      },
    ];
  }

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#97c05c",
    },
  })
);

export default AppMenu;
