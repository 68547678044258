/* eslint-disable no-template-curly-in-string */

import React, { Component } from "react";
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
// import {ProgressBar} from 'react-bootstrap';
import { Paper } from "@material-ui/core/";
// import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// import ApiService from "../../api/ApiService";
// import { withAlert } from 'react-alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
export class TradeImportComponent extends Component {


  state = {
    uploadPercentage: 0,
    avatar: '',
    errorMessage: '',
    searchQuery: "",
    searchQueryS: "",
    open: false,
    arrayMessages:[],
    uploadBrokerId:'',
    succesM:'',
    succesMD:'',
    succesMDS:[],
  }
  // handleClose = this.handleClose.bind(this)
//   openDialog() {
//     this.setState({ open: true });
// }
handleClose() {
  this.setState({
    open: false
  })
}
  // componentDidMount() {
  //   const brokerId = localStorage.getItem("brokerId");
  //   const url = ApiService.UploadNewUserData(brokerId);

  //   UploadService.getFiles().then((response) => {
  //     console.log(res)
  //     this.setState({ avatar: res.data.url, uploadPercentage: 100 }, ()=>{
  //       setTimeout(() => {
  //         this.setState({ uploadPercentage: 0 })
  //       }, 1000);
  //     })
  //   });


  
  // }


  $route: any;

  componentDidUpdate(){
    setTimeout(() => this.setState({errorMessage:''}), 4000);
  }

  uploadFile = ({ target: { files } }) =>{
    // let  brokerId  = localStorage.getItem("brokerId");
    // this.setState({ uploadBrokerId: brokerId });
    // console.log(brokerId);
    console.log( files[0] );
    var data = new FormData();
    // var brokerIdAl; 
    //  if(brokerId!==null)
    // {
    //    brokerIdAl= brokerId;
    // }
        // data.append('brokerId', brokerIdAl);
        data.append( 'file', files[0] )
    
    // data.append = localStorage.getItem("brokerId");
    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor( (loaded * 100) / total )
        console.log( `${loaded}kb of ${total}kb | ${percent}%` );

        if( percent < 100 ){
          this.setState({ uploadPercentage: percent })
        }
      }
    }    
    axios.post("https://gatewayapi.invest19.com/edis/upload/settlement/master/", data, options).then(res => { 
      
        console.log(res)
        this.setState({ avatar: res.data.url, uploadPercentage: 100 }, ()=>{
          setTimeout(() => {
            this.setState({ uploadPercentage: 0 })
            this.setState({ setShowAlert: null });
            this.setState({ showAlert: null });
            this.setState({ open: true });
            this.handleClose = this.handleClose.bind(this);
            // this.setShowAlert(res.data.result);
            
            // this.setState({
            //   searchQuery: JSON.stringify(res.data.failed), searchQueryS: JSON.stringify(res.data.success),
            // });
            this.setState({
              searchQuery: res.data.failed, searchQueryS: res.data.success,
            });
            this.setState({
              succesM: res.data.failed, succesMD: res.data.success,
            });


          }, 1000);
          this.state.arrayMessages = res.data.failed;

          this.state.succesMDS = res.data.success;
          // this.arrayMessages = res.data.failed;

          console.log(res.data)
        //  alert(res.data.failed + res.data.success );
// alert(JSON.stringify(res.data.failed))
        })
    })
    
    .catch(err => { 
      this.setState({errorMessage: err.message});
    })
  }

  render() {
    const {uploadPercentage} = this.state;
    return (
      <Grid className="CoomonT  uploadPt">
{/* <Alert> data: { this.state.searchQuery } </Alert> */}

                <Dialog 
                open={this.state.open}
                onClose={this.handleClose}
                className="cutomeFac"
                >
                    <DialogTitle>CSV Uploaded</DialogTitle>
                    <DialogContent>

                    {/* {!this.state.searchQueryS ? <div>no data</div> : <Alert> { this.state.searchQueryS } </Alert>} */}

                    {/* {this.state.searchQueryS && <Alert> Success: { this.state.searchQueryS } </Alert>} */}
                    {this.state.succesMDS &&
                    <Alert> 
                      <h4>Success:</h4> 
                      <ul>
                          {this.state.succesMDS.map(listitemSS => (
                                     <li className="list-group-item list-group-item-primary">
                                     {listitemSS}
                                   </li>
                          ))}

                    </ul>
                       </Alert>}



                    {this.state.arrayMessages &&
                    <Alert className="failedR" severity="error"> 
                      <h4>Failed:</h4> 
                      <ul>
                          {this.state.arrayMessages.map(listitem => (
                                     <li className="list-group-item list-group-item-primary">
                                     {listitem}
                                   </li>
                          ))}

                    </ul>
                       </Alert>}

                      {/* <Alert className="failedR" severity="error"> Failed: { this.state.searchQuery } </Alert> */}
                    </DialogContent>
                    <CloseIcon
                                onClick={this.handleClose}
                                color="primary"
                                className="cls_btn"
                              />
                </Dialog>

        <div className="" >
      <Grid className="CoomonT newUiupadte">
        <Paper className="tableP">
          <Grid container className="listContainer addDatasectionbroker csvupload">
            <form className="dpinformation">
              <div className="divider mb-0">
                <h2>Upload  CSV File here </h2>
                { this.state.errorMessage && <Alert severity="error" className="hide">{ this.state.errorMessage }</Alert>}
                { uploadPercentage >= 100 && <Alert severity="success" className="hide">Done</Alert> }
                <div className="card card-user">
                  <div className="content">
                    <div className="author">
                      <a href="#pablo">
                      <button className="btn">Upload a file</button>
                        <input type="file" className="form-control profile-pic-uploader" accept=".csv" onChange={this.uploadFile} />
                      </a>
                    </div>
                  </div>
                </div>
                <br/>
                { uploadPercentage > 0 && <LinearProgress variant="determinate" value={uploadPercentage}  />  }
                        { uploadPercentage > 0 && <p>{`${uploadPercentage}%`}</p> }
                        {/* { uploadPercentage >= 100 && <p>Done</p> } */}
              </div>
            </form>
          </Grid>
        </Paper>
      </Grid> 
      </div>
      </Grid>     
    );
  }
}

export default TradeImportComponent;
