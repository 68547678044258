import React from 'react';
import axios from 'axios';
import { Grid } from "@material-ui/core/";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NotificationPage extends React.Component<any, any>{
  constructor(props) {
    super(props)
    this.state = {
    }
  }



  componentDidMount() {
    this.getNotification()
  };

  getNotification() {
    let rId = this.props.rowId;
    axios.get(`https://gatewayapi.invest19.com/notification/send/ipo/${rId}?mobiles=`)

  };


  render() {
    return (

      <Grid className="CoomonT">
        <div>
          <form onSubmit={this.state.getNotification}>
            <div>
              <div>
              <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Notification Sent successfully!
            </Alert>
             </div>
            </div>
          </form>
          
        </div>

      </Grid>

    )
  }
}
export default NotificationPage;
