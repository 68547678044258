import React, { Component } from 'react';
import axios from "axios";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

class FinancialImportComponent extends React.Component<any,any>{

  
   // API Endpoints
   custom_file_upload_url = `https://gatewayapi.invest19.com/api/ipo/document/upload`;
   canvas: any;
 
     // state
     constructor(props) {
       super(props);
       this.canvas = React.createRef()
       this.state = {
           image_file: null,
           image_preview: '',
           uploadURL:'',
           url:''
       }
 }  
 
     // Image Preview Handler
     handleImagePreview = (e) => {
         let image_as_base64 = URL.createObjectURL(e.target.files[0])
         let image_as_files = e.target.files[0];
 
         this.setState({
             image_preview: image_as_base64,
             image_file: image_as_files,
         })
     }

     // Image/File Submit Handler
     handleSubmitFile = () => {
       if (this.state.image_file !== null){
 
           let formData = new FormData();
           formData.append('ipoName', 'ipo');
           formData.append('document', this.state.image_file);




           var ref= this;
           axios.post(
               this.custom_file_upload_url,
               formData,
               {
                   headers: {
                       // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                       "Content-type": "multipart/form-data",
                   },                    
               }
           )
           .then(res => {
 
            const options = {
                onUploadProgress: (progressEvent) => {
                  const {loaded, total} = progressEvent;
                  let percent = Math.floor( (loaded * 100) / total )
                  console.log( `${loaded}kb of ${total}kb | ${percent}%` );
          
                  if( percent < 100 ){
                    this.setState({ uploadPercentage: percent })
                  }
                }
              }

             this.setState({ avatar: res.data.url, uploadPercentage: 100, options }, ()=>{
                 setTimeout(() => {
                   this.setState({ uploadPercentage: 0 })
                 }, 1000000);
               })
 
             
               console.log(`Success` + res.data.response);
               var json= JSON.parse(JSON.stringify(res.data.response));
               console.log(ref.props);
               ref.props.upload(json.url);
           })
           .catch(err => {
               console.log(err);
           })
       }
   }
  
 
 
     // componentDidMount() {
     //   axios.post(`https://gatewayapi-uat.invest19.com/api/ipo/document/upload`)
     //     .then(res => {
     //       const persons = res.data.response;
     //       this.setState({ persons });
     //     })
     // }
 
     // render from here
     render() { 
         const {uploadPercentage} = this.state;
         return (
           <div className="custom-image-upload">
                    { this.state.errorMessage && <Alert severity="error" className="hide">{ this.state.errorMessage }</Alert>}
                 { uploadPercentage >= 100 && <Alert severity="success" className="hide">Done</Alert> }
           <input
               type="file" accept="application/pdf"
               onChange={this.handleImagePreview}
               
           />
 
           { uploadPercentage > 0 && <LinearProgress variant="determinate"  value={uploadPercentage}  />  }
                         {/* { uploadPercentage > 0 && <p>{`${uploadPercentage}%`}</p> } */}
                         {/* { uploadPercentage >= 100 && <p>Done</p> } */}
 
 
           <IconButton onClick={this.handleSubmitFile}>
               <CheckRoundedIcon/>
               </IconButton>
 
       </div>
         );
     }
    }
export default FinancialImportComponent;
