import {
  MODIFY_USER_DATA,
  MODIFY_USER_DATA_SUCCESS,
  MODIFY_USER_DATA_ERROR,
  FETCH_USER_LIST_DATA,
  FETCH_USER_LIST_DATA_SUCCESS,
  FETCH_USER_LIST_DATA_ERROR,
  CLOSE_USER_DATA,
  CLOSE_USER_DATA_SUCCESS,
  CLOSE_USER_DATA_ERROR,
  ADD_USER_DATA,
  ADD_USER_DATA_SUCCESS,
  ADD_USER_DATA_ERROR,
  PUBLISH_USER_DATA,
  PUBLISH_USER_DATA_SUCCESS,
  PUBLISH_USER_DATA_ERROR,
  FALSE_SHOW_MESSAGE,


  MODIFY_IPO_DATA,
  MODIFY_IPO_DATA_SUCCESS,
  MODIFY_IPO_DATA_ERROR,


  // settlement


  FETCH_SETTLEMENT_DATA,
  FETCH_SETTLEMENT_SUCCESS,
  FETCH_SETTLEMENT_ERROR,


  MODIFY_SETTLEMENT_DATA,
  MODIFY_SETTLEMENT_SUCCESS,
  MODIFY_SETTLEMENT_ERROR,

} from "../actionTypes";

const istate = {
  fetchingUserList: false,
  fetchedUserList: false,
  modifyingData: false,
  modifiedData: false,
  closingUser: false,
  closedUser: false,
  addingUserData: false,
  addedUserData: false,
  publishingData: false,
  publishedData: false,
  allData: [],
  message: "",
  statusCode: "",
  showMessage: false,
};

export default function userListsReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_USER_LIST_DATA: {
      return {
        ...state,
        fetchingUserList: true,
        fetchedUserList: false,
      };
    }
    case FETCH_USER_LIST_DATA_SUCCESS: {
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingUserList: false,
        fetchedUserList: true,
        allData: action.payload,
      };
    }
    case FETCH_USER_LIST_DATA_ERROR: {
      return {
        ...state,
        fetchedUserList: false,
        userListDataError: action.payload,
      };
    }

    case MODIFY_IPO_DATA: {
      return {
        ...state,
        modifyingData: true,
        modifiedData: false,
      };
    }

    case MODIFY_IPO_DATA_SUCCESS: {
      return {
        ...state,
        modifyingData: false,
        modifiedData: true,
        allData: action.payload.data,
        message: "Modified Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case MODIFY_IPO_DATA_ERROR: {
      return {
        ...state,
        modifiedData: false,
        modifyUserError: action.payload,
      };
    }





    // settlement

    case FETCH_SETTLEMENT_DATA: {
      return {
        ...state,
        modifyingData: true,
        modifiedData: false,
      };
    }

    case FETCH_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        modifyingData: false,
        modifiedData: true,
        allData: action.payload.data,
        message: "Modified Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case FETCH_SETTLEMENT_ERROR: {
      return {
        ...state,
        modifiedData: false,
        modifyUserError: action.payload,
      };
    }





    case MODIFY_SETTLEMENT_DATA: {
      return {
        ...state,
        modifyingData: true,
        modifiedData: false,
      };
    }

    case MODIFY_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        modifyingData: false,
        modifiedData: true,
        allData: action.payload.data,
        message: "Modified Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case MODIFY_SETTLEMENT_ERROR: {
      return {
        ...state,
        modifiedData: false,
        modifyUserError: action.payload,
      };
    }

    // settlement












    case MODIFY_USER_DATA: {
      return {
        ...state,
        modifyingData: true,
        modifiedData: false,
      };
    }

    case MODIFY_USER_DATA_SUCCESS: {
      return {
        ...state,
        modifyingData: false,
        modifiedData: true,
        allData: action.payload.data,
        message: "Modified Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case MODIFY_USER_DATA_ERROR: {
      return {
        ...state,
        modifiedData: false,
        modifyUserError: action.payload,
      };
    }





    case PUBLISH_USER_DATA: {
      return {
        ...state,
        publishingData: true,
        publishedData: false,
      };
    }

    case PUBLISH_USER_DATA_SUCCESS: {
      return {
        ...state,
        publishingData: false,
        publishedData: true,
        allData: action.payload.data,
        message: "Published Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case PUBLISH_USER_DATA_ERROR: {
      return {
        ...state,
        publishedData: false,
        publishUserError: action.payload,
      };
    }

    case ADD_USER_DATA: {
      return {
        ...state,
        addingUserData: true,
        addedUserData: false,
        showMessage: false,
      };
    }

    case ADD_USER_DATA_SUCCESS: {
      return {
        ...state,
        addingUserData: false,
        addedUserData: true,
        allData: action.payload.data,
        message: action.payload.message,
        statusCode: action.payload.statusCode,
      };
    }

    case ADD_USER_DATA_ERROR: {
      return {
        ...state,
        addedUserData: false,
        message: action.payload,
        statusCode: action.payload.statusCode,
      };
    }

    case CLOSE_USER_DATA: {
      return {
        ...state,
        closingUser: true,
        closedUser: false,
      };
    }

    case CLOSE_USER_DATA_SUCCESS: {
      return {
        ...state,
        closingUser: false,
        closedUser: true,
        allData: action.payload.data,
        message: "Closed Call Successfully",
        statusCode: action.payload.statusCode,
        showMessage: true,
      };
    }

    case CLOSE_USER_DATA_ERROR: {
      return {
        ...state,
        closedUser: false,
        closedUserError: action.payload,
      };
    }

    case FALSE_SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: false,
        message: "",
        statusCode: "",
      };
    }

    default:
      return state;
  }
}
