import * as React from "react";
import { Paper, TextField } from "@material-ui/core/";
import {
  AutoComplteState,
  AutoCompleteProp,
} from "../../adapter/usecase/AutoComplete";
import ApiService from "../../api/ApiService";

class AutoCompleteScrip extends React.Component<
  AutoCompleteProp,
  AutoComplteState
> {
  state: AutoComplteState = {
    suggestions: [],
    text: {
      symbol: "",
    },
    resetForm: false,
    cmp: null,
  };
  onTextChange = (e: any) => {
    const value = e.target.value;
    let suggestions: any = [];

    if (value.length > 2) {
      setTimeout(() => {
        ApiService.searchScripDataList({ symbolOrName: value }).then((res) => {
          this.setState({
            suggestions: res.data.data,
          });
        });
      }, 1000);
    }

    this.setState(() => ({
      suggestions,
      text: { symbol: value },
    }));
  };

  selectedText(value: any) {
    this.setState(() => ({
      text: value,
      suggestions: [],
    }));

    this.props.selectedName(value);

    let bowSessionID = localStorage.getItem("bowSessionID");

    if (bowSessionID !== null) {
      bowSessionID = bowSessionID.slice(1, -1);
    }
    // alert(bowSessionID);
    let cmpBody = {
      scripTokenNumber: value.scripId,
      exchangeId: value.exchange === "BSE" ? 1 : 2,
      sessionKey: bowSessionID,
    };
    console.log(cmpBody, "cmpBody");
    ApiService.cmpData(cmpBody).then((res) => {
      console.log(res, "cmp");
      if (res.data.statusCode === 200) {
        this.props.currentPrice(
          res.data.body.MarketDepthData[0].LastTradePrice
        );
      }
    });
  }

//broker image url
addDefaultSrc(ev){
  ev.target.src = 'https://invest19.com/notfound.png'
}



  componentWillReceiveProps(nextProps) {
    if (nextProps.resetForm && this.state.resetForm === false) {
      this.setState({
        suggestions: [],
        text: {
          symbol: "",
        },
        resetForm: true,
      });
    }
    if (!nextProps.resetForm && this.state.resetForm !== false) {
      this.setState({
        resetForm: false,
      });
    }
  }
  renderSuggestions = () => {
    let { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.map((item: any, index: any) => (
          <li
            className="autocomplete-list-item"
            key={index}
            onClick={() => {
              this.selectedText(item);
            }}
          >
            {item.image ? <img src={item.image} onError={this.addDefaultSrc} alt="symbol" /> : null}
            {item.symbol} <span>{item.exchange} </span>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { text, suggestions } = this.state;
    return (
      <>
        <TextField
          fullWidth
          id="query"
          label="Enter Scrip"
          type="text"
          name="symbol"
          onChange={this.onTextChange}
          value={text.symbol}
          autoComplete="off"
          required
        />
        <Paper> {this.renderSuggestions()}</Paper>
      </>
    );
  }
}

export default AutoCompleteScrip;
