import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { TableHeader, Pagination, Search } from "../DataTable";
import UserListUseCaseHanlder from "../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../Utils/Loader";
import CloseIcon from "@material-ui/icons/Close";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Publish";
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Tooltip from "@material-ui/core/Tooltip";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TradeImportComponent from "../Ipo/AddLogo";
import RHPImportComponent from "../Ipo/AddRHP";
import FinancialImportComponent from "../Ipo/AddFinancial";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import FaqPage from "../Ipo/faqPageNew";
import NotificationPage from "../Ipo/NotificationPage";
import AutoCompleteScripIpo from "../User/AutoCompleteScripIpo";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {
  Select,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core/";
import ApiService from "../../api/ApiService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@material-ui/core/DialogContentText';

import axios from "axios";

const AgentMaster = (props) => {
  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editaBtnClicked, setEditaBtnClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);
  const [publishModal, setPublishModal] = React.useState(false);
  const [resetForm, setResetform] = useState(false)
  const [exchange_Id, setExchange] = useState("")
  const [scripId, setScripId] = useState("")
  const [defaultData, setDefaultData] = React.useState({
    categoriesName: "",
    categoriesSegmentName: "",
    exchName: "",
    marketName: "",
    buySellInd: "",
  });
  const [notification, setNofication] = React.useState(true);
  const handlePublishChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  const handleModifyChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };
  const handleCloseChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };




  const [
    fairPriceValidationMessage,
    setFairPriceValidationMessage,
  ] = React.useState("");

  const [
    expectedReturnInDurationError,
    setExpectedReturnInDurationError,
  ] = React.useState("");

  const [
    expectedReturnInPercentageError,
    setExpectedReturnInPercentageError,
  ] = React.useState("");


  const [isOpeningDialog, setisOpeningDialog] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);

  const [isOpen, setisOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setisOpen(true);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [isOpenTwo, setisOpenTwo] = React.useState(false);
  const handleDialogOpenTwo = (e, id) => {
    setisOpenTwo(true);
    setRowId(id);
  };


  const [isOpenThree, setisOpenThree] = React.useState(false);
  const handleDialogOpenThree = (e, id) => {
    setisOpenThree(true);
    setRowId(id);
  };



  const handleDialogCloseTwo = () => {
    setisOpenTwo(false);
  };
  const handleDialogCloseThree = () => {
    setisOpenThree(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [editableData, setEditableData] = useState({

    id: "",
    open_Date: '',
    close_Date: '',
    listing_Date: '',
    allotmentDate: '',
    symbol: '',
    range: '',
    allot_Price: '',
    bse_Code: '',
    companyName: '',
    face_Value: '',
    issue_Price_H: '',
    foundationYear: '',
    grey_Mkt_Premium: '',
    tick_Size: '',
    cat_Allowed: '',
    application_series_start: '',
    application_series_end: '',
    rhp: '',
    youtube_link: '',
    youtubeTagline: '',
    script_id: '',
    issue_Price_L: '',
    issue_Type: '',
    logo: '',
    min_Amt: '',
    min_Qty: '',
    open_Time: '',
    close_Time: '',
    rating: '',
    industry: '',
    exchangetobe_Listed: '',
    max_Investment: '',
    fresh_Issue: '',
    offer_For_Sale: '',
    financial_Pdf: '',
    designation: '',
    founder: '',
    about_Company: '',
    issueSize: '',
    displayDate: '',
    exchange_Id: '',
    applicationSeriesApplied: '',


    // extra field for website
    employeeDiscountWebsite:'',
    outstandingLitigationWebsite:'',
    financialStatementWebsite:'',
    recommendationWebsite:'',
    offerForSaleWebsite:'',
    freshIssueWebsite:'',
    peerComparison:'',
    retailAllocationWebsite:'',
    promoterOfTheCompany: '',
  });




  const [userMetaInfo, setUserMetaInfo] = useState({
    addCall: "",
    adminRollType: "",
    closedCall: "",
    createdAt: "",
    modifyCall: "",
    publishCall: "",
  });


  const [meta, setMeta] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onHandlerKeyUpExp = (event) => {
    // alert("test");
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInPercentageError("Decimal value not allowed");
          } else {
            setExpectedReturnInPercentageError("");
          }
        }
      }
    }
  };

  const onHandlerKeyUpReturnInDuration = (event) => {
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInDurationError("Decimal value not allowed");
          } else {
            setExpectedReturnInDurationError("");
          }
        }
      }
    }
  };




  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getApiData());
  }, [message]);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
  }, []);

  const editUser = (data: any) => {
    // alert(JSON.stringify(data));
    setOpen(true);
    setEditaBtnClicked(true);
    setEditableData(data);
  };

  const handleChange = (e: any) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
  };
  // const handleKeyUp = (event) => {
  //   if (event.target.value && editableData.currentMarketPrice) {
  //     if (
  //       parseInt(event.target.value) > 0 &&
  //       parseInt(event.target.value) < parseInt(editableData.currentMarketPrice)
  //     ) {
  //       setFairPriceValidationMessage("It Must be greater than CMP");
  //     } else if (
  //       parseInt(event.target.value) > parseInt(editableData.currentMarketPrice)
  //     ) {
  //       setFairPriceValidationMessage("");
  //     }
  //   }
  // };

  // const handleKeyUpCMP = (event) => {
  //   if (event.target.value && editableData.fairPrice) {
  //     if (
  //       parseInt(event.target.value) > 0 &&
  //       parseInt(event.target.value) < parseInt(editableData.fairPrice)
  //     ) {
  //       setFairPriceValidationMessage("");
  //     } else if (
  //       parseInt(event.target.value) > parseInt(editableData.fairPrice)
  //     ) {
  //       setFairPriceValidationMessage("It Must be greater than CMP");
  //     }
  //   }
  // };



  // const onHandlerKeyUpTargetExpPrice = (e: any) => {
  //   console.log(e.target.name, e.target.value);
  //   if (editableData.entryPrice !== "" && editableData.targetPrice !== "") {
  //     let targetPrice = parseInt(editableData.targetPrice);
  //     let entryPrice = parseInt(editableData.entryPrice);
  //     let expReturninPer = Math.abs(
  //       ((targetPrice - entryPrice) / entryPrice) * 100
  //     ).toFixed(2);
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: expReturninPer,
  //     });
  //   } else if (
  //     editableData.entryPrice === "" &&
  //     editableData.targetPrice === ""
  //   ) {
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: "",
  //     });
  //   }
  // };

  const [StateVariable, setStateVariable] = useState({
    symbol: "",
    scripId: "",
    exchange: ""
  })
  const handleSearchScripName = (scripData) => {
    console.log('scrpt', scripData)
    setExchange(scripData.exchange)
    setScripId(scripData.scripId)
    // setStateVariable({
    //   symbol: scripData.symbol,
    //   scripId: scripData.scripId,
    // });
  };



  const [StateCmpdData, setStateCmpdData] = useState({
    currentMarketPrice: ""
  })
  const handleCmpdData = (data) => {
    setStateCmpdData({
      currentMarketPrice: data,
    });
  };


  const toggleReset = (value) => {
    setResetform(value)
  };








  const [StateCategory, setStateCategory] = useState({
    symbol: "",
    scripId: "",
    exchange: ""
  })
  const handleCategoryChange = (data) => {
    setStateCategory({
      symbol: data.symbol,
      scripId: data.scripId,
      exchange: data.exchange
    });
  };







  const handleSubmit = (e: any) => {
    e.preventDefault();
    // alert(editableData);
    let data = {
      id: editableData.id,
      open_Date: editableData.open_Date,
      close_Date: editableData.close_Date,
      listing_Date: editableData.listing_Date,
      allotmentDate: editableData.allotmentDate,
      symbol: editableData.symbol,
      range: editableData.range,
      allot_Price: editableData.allot_Price,
      bse_Code: editableData.bse_Code,
      companyName: editableData.companyName,
      face_Value: editableData.face_Value,
      issue_Price_H: editableData.issue_Price_H,
      foundationYear: editableData.foundationYear,
      grey_Mkt_Premium: editableData.grey_Mkt_Premium,
      tick_Size: editableData.tick_Size,
      cat_Allowed: editableData.cat_Allowed,
      application_series_start: editableData.application_series_start,
      application_series_end: editableData.application_series_end,
      rhp: editableData.rhp,
      youtube_link: editableData.youtube_link,
      youtubeTagline: editableData.youtubeTagline,
      issue_Price_L: editableData.issue_Price_L,
      issue_Type: editableData.issue_Type,
      logo: editableData.logo,
      min_Amt: editableData.min_Amt,
      min_Qty: editableData.min_Qty,
      open_Time: editableData.open_Time,
      close_Time: editableData.close_Time,
      rating: editableData.rating,
      industry: editableData.industry,
      exchangetobe_Listed: editableData.exchangetobe_Listed,
      max_Investment: editableData.max_Investment,
      fresh_Issue: editableData.fresh_Issue,
      offer_For_Sale: editableData.offer_For_Sale,
      financial_Pdf: editableData.financial_Pdf,
      designation: editableData.designation,
      founder: editableData.founder,
      about_Company: editableData.about_Company,
      issueSize: editableData.issueSize,
      displayDate: editableData.displayDate,
      exchange_Id: exchange_Id==="BSE"?1:2,
      script_id: scripId,
      applicationSeriesApplied: editableData.applicationSeriesApplied,

    // extra field for website
    employeeDiscountWebsite:editableData.employeeDiscountWebsite,
    outstandingLitigationWebsite:editableData.outstandingLitigationWebsite,
    financialStatementWebsite:editableData.financialStatementWebsite,
    recommendationWebsite:editableData.recommendationWebsite,
    offerForSaleWebsite:editableData.offerForSaleWebsite,
    freshIssueWebsite:editableData.freshIssueWebsite,
    peerComparison:editableData.peerComparison,
    retailAllocationWebsite:editableData.retailAllocationWebsite,
    promoterOfTheCompany: editableData.promoterOfTheCompany,
    };
    console.log(data, "modified data");
    modifyCall(data);
    setEditableData({
      id: "",
      open_Date: '',
      close_Date: '',
      listing_Date: '',
      allotmentDate:'',
      symbol: '',
      range: '',
      allot_Price: '',
      bse_Code: '',
      companyName: '',
      face_Value: '',
      issue_Price_H: '',
      foundationYear: '',
      grey_Mkt_Premium: '',
      tick_Size: '',
      cat_Allowed: '',
      application_series_start: '',
      application_series_end: '',
      rhp: '',
      youtube_link: '',
      youtubeTagline: '',
      script_id: '',
      issue_Price_L: '',
      issue_Type: '',
      logo: '',
      min_Amt: '',
      min_Qty: '',
      open_Time: '',
      close_Time: '',
      rating: '',
      industry: '',
      exchangetobe_Listed: '',
      max_Investment: '',
      fresh_Issue: '',
      offer_For_Sale: '',
      financial_Pdf: '',
      designation: '',
      founder: '',
      about_Company: '',
      issueSize: '',
      displayDate: '',
      exchange_Id: '',
      applicationSeriesApplied:'',

    // extra field for website
    employeeDiscountWebsite:'',
    outstandingLitigationWebsite:'',
    financialStatementWebsite:'',
    recommendationWebsite:'',
    offerForSaleWebsite:'',
    freshIssueWebsite:'',
    peerComparison:'',
    retailAllocationWebsite:'',
    promoterOfTheCompany: '',
    });
    setNofication(true);

  };


  const modifyCall = (data: any) => {
    dispatch(UserListUseCaseHanlder.modifyIPOCall(data));
    setOpen(false);
  };
  const addDefaultSrc = (e) => {
    e.target.src = "https://invest19.com/notfound.png";
    e.target.onerror = null;
  };




  const headers = [
    // { name: "ID", field: "id", sortable: true },
    { name: "symbol", field: "symbol", sortable: true },
    { name: "company", field: "companyName", sortable: false },
    { name: "industry", field: "industry", sortable: false },
    { name: "range", field: "range", sortable: false },
    { name: "Open Date", field: "openDate", sortable: true },
    { name: "Close Date", field: "closeDate", sortable: true },
    { name: "Listing Date", field: "listing_Date", sortable: false },
    { name: "allotmentDate", field: "allotmentDate", sortable: false },
    { name: "app. ser. start", field: "application_series_start", sortable: false },
    { name: "app. ser. end", field: "application_series_end", sortable: false },
    { name: "fin./RHP", field: "", sortable: false },
    { name: "status", field: "status", sortable: false },
    // { name: "Action", field: "", sortable: false },
    { name: "", field: "", sortable: false },
  ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
            data.symble.toLowerCase().includes(search.toLowerCase()) ||
            data.companyName.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas && computedDatas.length && computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);


  const clickHandle = () => {
    window.location.reload();
    // window.confirm("Hello World!");
    // console.log('You clicked the button')
  }



  //logo

  const onUpload = (url) => {
    setEditableData((oldValue) => {
      return { ...oldValue, logo: url };
    })
  }


  const onUploadrhp = (url) => {
    setEditableData((oldValuerhp) => {
      return { ...oldValuerhp, rhp: url };
    })
  }

  const onUploadFinancialI = (url) => {
    setEditableData((oldValuerhp) => {
      return { ...oldValuerhp, financial_Pdf: url };
    })
  }


  return (

    <>
      <div className="searchUpdatesectin noneS">
        <Search
          onSearch={(value) => {
            setSearch(value);
            setCurrentPage(1);
          }}
        />
      </div>
      <Paper className="tableP">
        <Grid container className="listContainer ipolisting newUiupadte">
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <TableBody>
              {fetchedUserList ? (
                datasData && datasData.map((data: any) => (
                  <TableRow id={data.id} key={data.id} className={data.id}>
                    {/* <TableCell align="left">{data.id}</TableCell> */}
                    <TableCell align="left" className="symble-logo"><span><img src={data.logo} onError={addDefaultSrc} alt={data.Symbol} /></span>
                      <span className="symble">{data.symbol}</span></TableCell>
                    <TableCell align="left"> <span className="symbleN">{data.companyName}</span></TableCell>
                    <TableCell align="left"><span className="symbleI">{data.industry}</span></TableCell>
                    <TableCell align="left">{data.range}</TableCell>
                    <TableCell align="left">{data.openDate}</TableCell>
                    <TableCell align="left">{data.closeDate}</TableCell>
                    <TableCell align="left">{data.listingDate}</TableCell>
                    <TableCell align="left">{data.allotmentDate}</TableCell>
                    <TableCell align="left">{data.application_series_start}</TableCell>
                    <TableCell align="left">{data.application_series_end}</TableCell>
                    <TableCell align="left"><a href={data.financial_Pdf}> <GetAppRoundedIcon /> </a><a href={data.rhp}> <GetAppRoundedIcon /> </a></TableCell>
                    <TableCell align="left">{data.status}</TableCell>

                    <TableCell align="left">
                      {/* <IconButton aria-label="delete" size="small">
                        
                        <DeleteRoundedIcon fontSize="small" 
                        
                        onClick={handleDialogOpen}
                        />
                      </IconButton> */}




                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setOpen(true);
                            setEditaBtnClicked(true);
                            setEditableData(data);
                          }}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {/* <Button variant="outlined" color="primary" >
        Open simple dialog
      </Button> */}
                      <Dialog open={isOpen} onClose={handleDialogClose}>
                        {/* <DialogTitle>Confirm</DialogTitle> */}
                        <DialogContent>
                          <DialogContentText>Are you sure?</DialogContentText>
                        </DialogContent>
                        <DialogActions className="basic-popupbtn">

                          <Button onClick={handleDialogClose} color="primary">
                            no
                          </Button>
                          {/* <Button onClick={(event) => { removeData(data.id); handleDialogClose(); clickHandle(); }} color="primary" autoFocus>
                            yes
                          </Button> */}
                        </DialogActions>
                      </Dialog>
                      <Tooltip title="Faq" aria-label="Faq" placement="top">
                        <IconButton aria-label="delete" color="primary" size="small" onClick={(e) => handleDialogOpenTwo(e, data.id)}>
                          <LiveHelpRoundedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send notification" aria-label="Send notification" placement="top">
                        <IconButton aria-label="delete" color="primary" size="small" onClick={(e) => handleDialogOpenThree(e, data.id)}>
                          <NotificationsActiveIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {/* <Button variant="outlined" color="primary" className="faq-btn" >
                        Faq
                        
                      </Button> */}




                    </TableCell>
                  </TableRow>

                ))

              ) : (

                <Loader />

              )}
            </TableBody>
          </Table>
          <div>
            <Dialog open={isOpenTwo} onClose={handleDialogCloseTwo} className="quistion_Popup">
              {/* <DialogTitle>Confirm</DialogTitle> */}
              <DialogContent>
              <DialogTitle id="alert-dialog-title" className="faq-l">{"Add Faq"}</DialogTitle>
                {/* <DialogContentText>Add Faq</DialogContentText> */}
                <FaqPage rowId={rowId} />
              </DialogContent>

            </Dialog>
          </div>

          <div>
            <Dialog open={isOpenThree} onClose={handleDialogCloseThree} className="notification_Popup">
              {/* <DialogTitle>Confirm</DialogTitle> */}
              <DialogContent>
                {/* <DialogContentText>Add Faq</DialogContentText> */}
                <NotificationPage rowId={rowId} />
              </DialogContent>

            </Dialog>
          </div>


          <div>

            {/* <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div> */}


            {editaBtnClicked && editableData ? (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <span>Do you want to Modify the IPO {editableData.companyName} ? </span>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      <CloseIcon />
                    </Button>
                  </DialogActions>
                </DialogTitle>

                <DialogContent>
                  <Grid container className="addDatasection">

                    <form
                      style={formContainer}
                      onSubmit={handleSubmit}
                      id="modifyipo"
                      autoComplete="off"
                    >
                      <div className="formW intop">
                        <TextField
                          label="open date"
                          type="date"
                          fullWidth
                          margin="normal" onChange={handleChange}
                          name="open_Date"
                          value={editableData.open_Date}
                        />
                      </div>
                      <div className="formW intop">
                        <TextField
                          label="Close Date"
                          fullWidth
                          type="date" onChange={handleChange}
                          margin="normal"
                          name="close_Date"
                          value={editableData.close_Date}

                        />
                      </div>


                      <div className="formW intop">
                        <TextField type="date" label="listing Date" name="listing_Date" onChange={handleChange} value={editableData.listing_Date} />
                      </div>
                      <div className="formW intop">
                        <TextField type="date" label="allotment Date" name="allotmentDate" onChange={handleChange} value={editableData.allotmentDate} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="Display Date" name="displayDate" onChange={handleChange} value={editableData.displayDate} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="symbol" name="symbol" onChange={handleChange} value={editableData.symbol} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="range" name="range" onChange={handleChange} value={editableData.range} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="allot Price" name="allot_Price" onChange={handleChange} value={editableData.allot_Price} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="issue Size" name="issueSize" onChange={handleChange} value={editableData.issueSize} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="bse Code" name="bse_Code" onChange={handleChange} value={editableData.bse_Code} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="company Name" name="companyName" onChange={handleChange} value={editableData.companyName} required />
                      </div>
                      <div className="formW">
                        <TextField type="number" label="face_Value" name="face_Value" onChange={handleChange} value={editableData.face_Value} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="issue Price H" name="issue_Price_H" onChange={handleChange} value={editableData.issue_Price_H} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="foundationYear" name="foundationYear" onChange={handleChange} value={editableData.foundationYear} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="grey Mkt Premium" name="grey_Mkt_Premium" onChange={handleChange} value={editableData.grey_Mkt_Premium} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="tick Size" name="tick_Size" onChange={handleChange} value={editableData.tick_Size} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="cat. Allowed" name="cat_Allowed" onChange={handleChange} value={editableData.cat_Allowed} />
                      </div>
                      <div className="formW">
                      
                        <TextField type="number" label="application series start" name="application_series_start" onChange={handleChange} value={editableData.application_series_start} />
                      </div>
                      <div className="formW">
                        <TextField type="number" label="application series end" name="application_series_end" onChange={handleChange} value={editableData.application_series_end} />
                      </div>
                      {/* <div className="formW">
                        <TextField type="text" label="applicationSeriesApplied" name="applicationSeriesApplied" onChange={handleChange} value={editableData.applicationSeriesApplied} />
                      </div> */}
                      <div className="formW">
                        <FormControl fullWidth>
                          <InputLabel>App. Series Available</InputLabel>
                          <Select
                            native
                            value={editableData.applicationSeriesApplied}
                            onChange={handleChange}
                            name="applicationSeriesApplied"
                          >
                            <option aria-label="None" value="" />
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </Select>
                        </FormControl>

                      </div>
                      <div className="formW dNoneP">
                        <TextField type="text" label="rhp" name="rhp" onChange={handleChange} value={editableData.rhp} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="youtube link" name="youtube_link" onChange={handleChange} value={editableData.youtube_link} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="youtube Tagline" name="youtubeTagline" onChange={handleChange} value={editableData.youtubeTagline} />
                      </div>

                      <div className="formW">
                        <TextField type="text" label="issue Price L" name="issue_Price_L" onChange={handleChange} value={editableData.issue_Price_L} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="issue Type" name="issue_Type" onChange={handleChange} value={editableData.issue_Type} />
                      </div>
                      <div className="formW dNoneP">
                        <TextField type="text" label="logo" name="logo" onChange={handleChange} value={editableData.logo} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="min Amt" name="min_Amt" onChange={handleChange} value={editableData.min_Amt} />
                      </div>
                      <div className="formW">
                        <TextField type="number" label="min Qty" name="min_Qty" onChange={handleChange} value={editableData.min_Qty} />
                      </div>
                      <div className="formW intop">
                        <TextField type="time" label="open Time" name="open_Time" onChange={handleChange} value={editableData.open_Time} />
                      </div>
                      <div className="formW intop">
                        <TextField type="time" label="close Time" name="close_Time" onChange={handleChange} value={editableData.close_Time} />
                      </div>
                      <div className="formW">
                        <TextField type="number" label="rating" name="rating" onChange={handleChange} value={editableData.rating} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="industry" name="industry" onChange={handleChange} value={editableData.industry} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="exchangetobe Listed" name="exchangetobe_Listed" onChange={handleChange} value={editableData.exchangetobe_Listed} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="max Investment" name="max_Investment" onChange={handleChange} value={editableData.max_Investment} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="fresh Issue" name="fresh_Issue" onChange={handleChange} value={editableData.fresh_Issue} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="offer For Sale" name="offer_For_Sale" onChange={handleChange} value={editableData.offer_For_Sale} />
                      </div>
                      <div className="formW dNoneP">
                        <TextField type="text" label="financial Pdf" name="financial_Pdf" onChange={handleChange} value={editableData.financial_Pdf} />
                      </div>


                      <div className="formW">
                        <FormControl fullWidth>
                          <InputLabel>Designation name</InputLabel>
                          <Select
                            native
                            value={editableData.designation}
                            onChange={handleChange}
                            name="designation"
                          >
                            <option aria-label="None" value="" />
                            <option value="MD">MD</option>
                            <option value="CEO">CEO</option>
                            <option value="MD & CEO">MD & CEO</option>
                            <option value="Founder">Founder</option>
                            <option value="Founder & CEO">Founder & CEO</option>
                            <option value="Chairman">Chairman</option>
                            <option value="Chairman & MD">Chairman & MD</option>
                            <option value="Promoter">Promoter</option>
                          </Select>
                        </FormControl>

                      </div>
                      <div className="formW">
                        <TextField type="text" label="Name" name="founder" onChange={handleChange} value={editableData.founder} />
                      </div>
                      <div className="formW">
                        <AutoCompleteScripIpo
                          selectedName={handleSearchScripName}
                          currentPrice={handleCmpdData}
                          resetForm={resetForm}
                          toggleReset={toggleReset}
                        />
                      </div>
                      <div className="formW">
                        <TextField
                          label="Exchange Id"
                          fullWidth
                          margin="normal"
                          name="exchange_Id"
                          value={exchange_Id}
                          disabled
                        />
                      </div>
                      <div className="formW">
                        <TextField
                          label="script id"
                          fullWidth
                          margin="normal"
                          name="script_id"
                          value={scripId}
                          disabled
                        />
                      </div>

                      <div className="formW about_Text">
                        <label className="MuiFormLabel-root MuiInputLabel-root  MuiInputLabel-animated">About Company</label>
                        <TextareaAutosize aria-label="About Company" name="about_Company" onChange={handleChange} value={editableData.about_Company} />
                      </div>

                      <div className="inUpload">
                        <div className="formW">
                          <label>Upload Logo</label>
                          <TradeImportComponent upload={onUpload} />
                        </div>

                        <div className="formW">
                          <label>Upload RHP PDF</label>
                          <RHPImportComponent upload={onUploadrhp} />
                        </div>

                        <div className="formW">
                          <label>Upload Financial PDF</label>
                          <FinancialImportComponent upload={onUploadFinancialI} />
                        </div>
                      </div>

                      <br />
                      <div className="formW about_Text">
                      <h4>Field for web ipo</h4>
                      </div>

                      <div className="formW">
                        <TextField type="text" label="employee Discount" name="employeeDiscountWebsite" onChange={handleChange} value={editableData.employeeDiscountWebsite} />
                      </div>
                      {/* <div className="formW">
                        <TextField type="text" label="recommendation Website" name="recommendationWebsite" onChange={handleChange} value={editableData.recommendationWebsite} />
                      </div> */}
                      <div className="formW">
                        <TextField type="text" label="offer For Sale Website" name="offerForSaleWebsite" onChange={handleChange} value={editableData.offerForSaleWebsite} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="fresh Issue Website" name="freshIssueWebsite" onChange={handleChange} value={editableData.freshIssueWebsite} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="peerComparison" name="peerComparison" onChange={handleChange} value={editableData.peerComparison} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="retail Allocation Website" name="retailAllocationWebsite" onChange={handleChange} value={editableData.retailAllocationWebsite} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="promoter Of The Company" name="promoterOfTheCompany" onChange={handleChange} value={editableData.promoterOfTheCompany} />
                      </div>
                      <div className="formW about_Text">
                        <label className="MuiFormLabel-root MuiInputLabel-root  MuiInputLabel-animated">recommendation Website</label>
                        <TextareaAutosize aria-label="recommendation Website" name="recommendationWebsite" onChange={handleChange} value={editableData.recommendationWebsite} />
                      </div>
                      <div className="about_Text table_bc">
                        <span className="ansD">outstanding Litigation Website: </span>
                        <div
                          className="innerF"
                          dangerouslySetInnerHTML={{
                            __html: editableData.outstandingLitigationWebsite
                          }}></div>

                      </div>
                      <div className="about_Text table_bc">
                        <span className="ansD">financial Statement Website: </span>
                        <div
                          className="innerF"
                          dangerouslySetInnerHTML={{
                            __html: editableData.financialStatementWebsite
                          }}></div>

                      </div>
                      <div style={{ width: "100%" }} className="btnMar">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          value="submit"
                          // onClick={(event) => { clickHandle();}}
                          disableElevation
                        >
                          Yes
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </form>

                  </Grid>
                </DialogContent>
              </Dialog>
            ) : null}
          </div>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

export default AgentMaster;


