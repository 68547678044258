import React, { ReactElement, useState } from "react";
import { withRouter } from "react-router-dom";
import { IconButton, Toolbar, Typography, Link } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { NavLink } from "react-router-dom";

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

function HeaderComponent(props: any): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const { match, location, history } = props;
  const [menu, setMenu] = useState(false);

  const handleSidebarMobile = () => {
    let element = document.getElementById("sidebar");
    let width = window.innerWidth;
    if (width < 768) {
      if (element !== null) {
        element.classList.toggle("show");
        setMenu(!menu);
      }
    }
  };
  window.addEventListener("resize", function (event) {
    let width = window.innerWidth;

    let element = document.getElementById("sidebar");
    if (element !== null && element.classList.contains("show")) {
      if (width > 767) {
        element.classList.remove("show");
      }
    }
  });

  // const [brokerUrl, setBrokerUrl] = useState("");
  // useEffect(() => {
  //   let brokerId = localStorage.getItem("brokerId");
  //   if (brokerId !== null) {
  //     ApiService.fetchBrokerInfo(brokerId).then((response) => {
  //       setBrokerUrl(response.data.body.data[0].brokerLogoUrl);
  //     });
  //   }
  // }, [brokerUrl]);

  if (props.location.pathname === "/login") {
    return <> </>;
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar>
        <div className="mobileHideDesktopHamburger">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: props.open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="mobileHamburger">
          <IconButton
            color="inherit"
            onClick={handleSidebarMobile}
            edge="start"
          >
            {menu ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Typography variant="h6" noWrap className="logo">
          <NavLink to="/">
            <img
              src="https://www.invest19.com/images/logo/invest19_logo-blue.svg"
              alt="logo"
            />
          </NavLink>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
export default withRouter(HeaderComponent);
