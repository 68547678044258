import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button, MenuItem } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import TradeImportComponent from "../Ipo/AddLogo";
import RHPImportComponent from "../Ipo/AddRHP";
import FinancialImportComponent from "../Ipo/AddFinancial";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AgentMaster from "../Ipo/AgentMaster";
import { FormControl } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core/";
import AutoCompleteScrip from "../User/AutoCompleteScrip";
import { Editor } from '@tinymce/tinymce-react';
class ipo extends React.Component<{},any>{
// export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.state = {checked: false};
    this.canvas = React.createRef()
    this.state = {
      open_Date: '',
    close_Date: '',
    listing_Date: '',
    allotmentDate:'',
    symbol: '',
    range: '',
    allot_Price: '',
    issueSize: '',
    bse_Code: '',
    company_Name: '',
    face_Value: '',
    issue_Price_H: '',
    foundationYear: '',
    grey_Mkt_Premium: '',
    tick_Size: '',
    cat_Allowed: '',
    application_series_start: '',
    application_series_end: '',
    rhp: '',
    youtube_link: '',
    youtubeTagline: '',
    script_id: '',
    issue_Price_L: '',
    issue_Type: '',
    logo: '',
    min_Amt: '',
    min_Qty: '',
    open_Time: '',
    close_Time: '',
    rating: '',
    industry: '',
    exchangetobe_Listed: '',
    max_Investment: '',
    fresh_Issue: '',
    offer_For_Sale: '',
    financial_Pdf: '',
    designation:'',
    founder: '',
    about_Company: '',
    data:[],
    errorMessage: '',
    sucessMessage: '',
    applicationSeriesApplied:'',


    image_file: null,
    image_preview: '',

    // extra field for website
    employeeDiscountWebsite:'',
    outstandingLitigationWebsite:'',
    financialStatementWebsite:'',
    recommendationWebsite:'',
    offerForSaleWebsite:'',
    freshIssueWebsite:'',
    peerComparison:'',
    retailAllocationWebsite:'',
    promoterOfTheCompany: '',

}
}
  componentDidUpdate(){
    setTimeout(() => this.setState({errorMessage:''}), 40000);
    setTimeout(() => this.setState({sucessMessage:''}), 40000);
  }


  handleSearchScripName = (scripData) => {
    this.setState({
      symbol: scripData.symbol,
      scripId: scripData.scripId,
      exchange: scripData.exchange,
    });
  };
  handleCmpdData = (data) => {
    this.setState({
      currentMarketPrice: data,
    });
  };
  toggleReset = (value) => {
    this.setState({
      resetForm: value,
    });
  };
    // Image Preview Handler
    handleImagePreview = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];

      this.setState({
          image_preview: image_as_base64,
          image_file: image_as_files,
      })
  }

  // Image/File Submit Handler
  handleSubmitFileUpload= () => {

      if (this.state.image_file !== null){

          let formData = new FormData();
          formData.append('ipoName', 'tata');
          formData.append('document', this.state.image_file);

          axios.post(
              this.custom_file_upload_url,
              formData,
              {
                  headers: {
                      // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                      "Content-type": "multipart/form-data",
                  },                    
              }
          )
          .then(res => {
              console.log(`Success` + res.data.response);
              var json= JSON.parse(JSON.stringify(res.data.response));
              console.log(json);
              console.log(json.url);
              console.log(this.canvas.current.value);
              // this.canvas.current.value=json.url;
              // console.log(this.canvas.current.value);
              return json.url;
          })
          .catch(err => {
              console.log(err);
          })
      }
  }



  uploadDocument = (e) => {
    console.log(e); 
  }



  handleUserIDChange = event => {this.setState({ open_Date: event.target.value })}
  handleFullNameChange = event => {this.setState({ close_Date: event.target.value })}
  handlelistingDate = event => {this.setState({ listing_Date: event.target.value })}
  handleallotmentDate = event => {this.setState({ allotmentDate: event.target.value })}
  handledisplayDate = event => {this.setState({ displayDate: event.target.value })}
  handlesymbol = event => {this.setState({ symbol: event.target.value })}
  handlerange = event => {this.setState({ range: event.target.value })}
  handleallot_Price = event => {this.setState({ allot_Price: event.target.value })}
  handleissueSize = event => {this.setState({ issueSize: event.target.value })}
  handlebse_Code = event => {this.setState({ bse_Code: event.target.value })}
  handlecompany_Name = event => {this.setState({ companyName: event.target.value })}
  handleface_Value = event => {this.setState({ face_Value: event.target.value })}
  handleissue_Price_H = event => {this.setState({ issue_Price_H: event.target.value })}
  handlefoundationYear = event => {this.setState({ foundationYear: event.target.value })}
  handlegrey_Mkt_Premium = event => {this.setState({ grey_Mkt_Premium: event.target.value })}
  handletick_Size = event => {this.setState({ tick_Size: event.target.value })}
  handlecat_Allowed = event => {this.setState({ cat_Allowed: event.target.value })}
  handleapplication_series_start = event => {this.setState({ application_series_start: event.target.value })}
  handleapplication_series_end = event => {this.setState({ application_series_end: event.target.value })}
  handlerhp = event => {this.setState({ rhp: this.handleSubmitFileUpload()  })}
  handleyoutube_link = event => {this.setState({ youtube_link: event.target.value })}
  handleyoutubeTagline = event => {this.setState({ youtubeTagline: event.target.value })}
  handlescript_id = event => {this.setState({ script_id: event.target.value })}
  handleissue_Price_L = event => {this.setState({ issue_Price_L: event.target.value })}
  handleissue_Type = event => {this.setState({ issue_Type: event.target.value })}
  handlelogo = event => {this.setState({ logo: event.target.value })}
  handlemin_Amt = event => {this.setState({ min_Amt: event.target.value })}
  handlemin_Qty = event => {this.setState({ min_Qty: event.target.value })}
  handleopen_Time = event => {this.setState({ open_Time: event.target.value })}
  handleclose_Time = event => {this.setState({ close_Time: event.target.value })}
  handlerating = event => {this.setState({ rating: event.target.value })}
  handleindustry = event => {this.setState({ industry: event.target.value })}
  handleexchangetobe_Listed = event => {this.setState({ exchangetobe_Listed: event.target.value })}
  handlemax_Investment = event => {this.setState({ max_Investment: event.target.value })}
  handlefresh_Issue = event => {this.setState({ fresh_Issue: event.target.value })}
handleoffer_For_Sale = event => {this.setState({ offer_For_Sale: event.target.value })}
handlefinancial_Pdf = event => {this.setState({ financial_Pdf: event.target.value })}
handlefounder = event => {this.setState({ founder: event.target.value })}
handledesignation = event => {this.setState({ designation: event.target.value })}
handleabout_Company = event => {this.setState({ about_Company: event.target.value })}
// handleapplicationSeriesApplied  =   event => {this.setState({ applicationSeriesApplied: event.target.value })}


    // extra field for website
    handleemployeeDiscountWebsite = event => {this.setState({ employeeDiscountWebsite: event.target.value })}
    handlefinancialStatementWebsite = event => {this.setState({ financialStatementWebsite: event.target.value })}
    handlerecommendationWebsite = event => {this.setState({ recommendationWebsite: event.target.value })}
    handleofferForSaleWebsite = event => {this.setState({ offerForSaleWebsite: event.target.value })}
    handlefreshIssueWebsite = event => {this.setState({ freshIssueWebsite: event.target.value })}
    handlepeerComparison = event => {this.setState({ peerComparison: event.target.value })}
    handleretailAllocationWebsite = event => {this.setState({ retailAllocationWebsite: event.target.value })}
    handlepromoterOfTheCompany = event => {this.setState({ promoterOfTheCompany: event.target.value })}
    // handleoutstandingLitigationWebsite = event => {this.setState({ outstandingLitigationWebsite: event })
    handleoutstandingLitigationWebsite = event => {
      this.setState({ outstandingLitigationWebsite: event })
    }

handleapplicationSeriesApplied = () => {
  this.setState({ applicationSeriesApplied: !this.state.false});
}
// handleabout_Company = event => {this.setState({ about_Company: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()

    // const openDate = {userid: this.state.openDate};
    // const closeDate = {fullname: this.state.closeDate};
    // const listingDate = {usergroup: this.state.listingDate};


    axios.post('https://gatewayapi.invest19.com/api/create/ipo',
      { open_Date: this.state.open_Date, 
        close_Date: this.state.close_Date, 
        listing_Date: this.state.listing_Date,
        allotmentDate:this.state.allotmentDate,
        displayDate: this.state.displayDate,
        symbol: this.state.symbol, 
        range: this.state.range, 
        allot_Price: this.state.allot_Price, 
        bse_Code: this.state.bse_Code, 
        companyName: this.state.companyName, 
        face_Value: this.state.face_Value, 
        issue_Price_H: this.state.issue_Price_H, 
        foundationYear: this.state.foundationYear, 
        grey_Mkt_Premium: this.state.grey_Mkt_Premium, 
        tick_Size: this.state.tick_Size, 
        cat_Allowed: this.state.cat_Allowed, 
        application_series_start: this.state.application_series_start, 
        application_series_end: this.state.application_series_end, 
        rhp: this.state.rhp, 
        youtube_link: this.state.youtube_link, 
        youtubeTagline: this.state.youtubeTagline, 
        script_id: this.state.script_id, 
        issue_Price_L: this.state.issue_Price_L, 
        issue_Type: this.state.issue_Type, 
        logo: this.state.logo, 
        min_Amt: this.state.min_Amt, 
        min_Qty: this.state.min_Qty, 
        open_Time: this.state.open_Time, 
        close_Time: this.state.close_Time, 
        rating: this.state.rating, 
        industry: this.state.industry, 
        exchangetobe_Listed: this.state.exchangetobe_Listed, 
        max_Investment: this.state.max_Investment, 
        fresh_Issue: this.state.fresh_Issue, 
        offer_For_Sale: this.state.offer_For_Sale, 
        financial_Pdf: this.state.financial_Pdf, 
        designation: this.state.designation, 
        founder: this.state.founder, 
        about_Company: this.state.about_Company,
        issueSize: this.state.issueSize,
        applicationSeriesApplied: this.state.applicationSeriesApplied,
            // extra field for website
        employeeDiscountWebsite:this.state.employeeDiscountWebsite,
        outstandingLitigationWebsite:this.state.outstandingLitigationWebsite,
        financialStatementWebsite:this.state.financialStatementWebsite,
        recommendationWebsite:this.state.recommendationWebsite,
        offerForSaleWebsite:this.state.offerForSaleWebsite,
        freshIssueWebsite:this.state.freshIssueWebsite,
        peerComparison:this.state.peerComparison,
        retailAllocationWebsite:this.state.retailAllocationWebsite,
        promoterOfTheCompany: this.state.promoterOfTheCompany,
      },
        
        )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you')
        this.setState({sucessMessage: res.data});
      })
    .catch(err => { 
      this.setState({errorMessage: err.message});
    })
  }


  onUpload = (url) => {
    console.log('I have been clicked', url);
    this.setState({logo:url})
  }
  onUploadrhp = (url) => {
    console.log('I have been clicked', url);
    this.setState({rhp:url})
  }
  onUploadFinancialI = (url) => {
    console.log('I have been clicked', url);
    this.setState({financial_Pdf:url})
  }

  
  render() {
    
    const callbacks = {upload: this.onUpload};
    const callbacksRHP = {upload: this.onUploadrhp};
    const callbacksFinancialI = {upload: this.onUploadFinancialI};
    return (
<div className="CoomonT mainCt">
  <div className="innerprofilesection">
      <Grid className="CoomonT">
        <div className="clapse ">
          <ExpansionPanel className="newUiupadte">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Button
                  size="small"
                  variant="contained"
                  className="changeBTN"
                  color="primary"
                  disableElevation
                >
                  Add New IPO
                </Button>
</ExpansionPanelSummary>


<ExpansionPanelDetails className="inner_ipo_form">
{ this.state.errorMessage && <Alert severity="error" className="errorMsd">{ this.state.errorMessage }</Alert>}
<form onSubmit={this.handleSubmit} autoComplete="nope">
<div className="formW intop">
<TextField type="date" label="open Date"  name="this.state.open_Date" onChange={this.handleUserIDChange}/>
</div>  
{/* <input type="checkbox" checked={this.state.false} onChange={this.handleapplicationSeriesApplied} className="filled-in" id="filled-in-box"/> */}
{/* <div className="formW">
              <AutoCompleteScrip
                selectedName={this.handleSearchScripName}
                currentPrice={this.handleCmpdData}
                resetForm={this.state.resetForm}
                toggleReset={this.toggleReset}
              />
            </div> */}
  <div className="formW intop">
    <TextField type="date" label="close Date" name="this.state.close_Date" onChange={this.handleFullNameChange} />
  </div>
  <div className="formW intop">
     <TextField type="date" label="listing Date" name="this.state.listing_Date" onChange={this.handlelistingDate} />
  </div>
  <div className="formW intop">
     <TextField type="date" label="allotment Date" name="this.state.allotmentDate" onChange={this.handleallotmentDate} />
  </div>
  <div className="formW">
     <TextField type="text" label="Display Date" name="this.state.displayDate" placeholder="formate: Oct'21" autoComplete="nope" onChange={this.handledisplayDate} />
  </div>
  <div className="formW">
    <TextField type="text" label="symbol"  name="this.state.symbol" onChange={this.handlesymbol} placeholder="formate: ABCD" required autoComplete="nope" />
  </div>
  <div className="formW">
    <TextField type="text" label="range" name="this.state.range" onChange={this.handlerange} placeholder="formate: ₹75-80" autoComplete="nope"/>
  </div>
  <div className="formW">
    <TextField type="text" label="allot Price" name="this.state.allot_Price" onChange={this.handleallot_Price} placeholder="formate: 80" autoComplete="nope"/>
  </div>
  <div className="formW">
    <TextField type="text" label="issue size" name="this.state.issueSize" onChange={this.handleissueSize} placeholder="formate: 309.38 cr" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="bse Code" name="this.state.bse_Code" onChange={this.handlebse_Code}  autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="company Name" name="this.state.companyName" onChange={this.handlecompany_Name} placeholder="formate: Abcd" required autoComplete="nope"/>
  </div>
  <div className="formW">
   <TextField type="number" label="face_Value" name="this.state.face_Value" onChange={this.handleface_Value} placeholder="formate: 1" autoComplete="nope"/>
  </div>
  <div className="formW">
    <TextField type="text" label="issue Price H" name="this.state.issue_Price_H" onChange={this.handleissue_Price_H} placeholder="formate: 80" autoComplete="nope"/>
  </div>
  <div className="formW">
 <TextField type="text" label="foundationYear" name="this.state.foundationYear" onChange={this.handlefoundationYear} placeholder="formate: 2011" autoComplete="nope"/>
  </div>
  <div className="formW">
   <TextField type="text" label="grey Mkt Premium" name="this.state.grey_Mkt_Premium" onChange={this.handlegrey_Mkt_Premium} placeholder="formate: 10" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="tick Size" name="this.state.tick_Size" onChange={this.handletick_Size} placeholder="formate: 3" autoComplete="nope"/>
  </div>
  <div className="formW">
    <TextField type="text" label="cat. Allowed" name="this.state.cat_Allowed" onChange={this.handlecat_Allowed} placeholder="formate: Individual or Employee" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="number" label="application series start" name="this.state.application_series_start" placeholder="formate: 74381101" onChange={this.handleapplication_series_start} autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="number" label="application series end" name="this.state.application_series_end" placeholder="formate: 74381100" onChange={this.handleapplication_series_end} autoComplete="nope"/>
  </div>
  
  <div className="formW">
   <TextField type="text" label="youtube link" name="this.state.youtube_link" onChange={this.handleyoutube_link} placeholder="formate: https://www.youtube.com/watch?v=PEMVqgXCTPE&t=22s" autoComplete="nope"/>
  </div>
   
  <div className="formW">
   <TextField type="text" label="youtube Tagline" name="this.state.youtubeTagline" onChange={this.handleyoutubeTagline} placeholder="formate: A Walkthrough Invest19 Mobile App – India’s Upcoming Saving & Investment Destination" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="script id" name="this.state.script_id" onChange={this.handlescript_id}  autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="issue Price L" name="this.state.issue_Price_L" onChange={this.handleissue_Price_L} placeholder="formate: 80" autoComplete="nope"/>
  </div>
  <div className="formW">
   <TextField type="text" label="issue Type" name="this.state.issue_Type" onChange={this.handleissue_Type}  autoComplete="nope"/>
  </div>
  
  <div className="formW">
  <TextField type="text" label="min Amt" name="this.state.min_Amt" onChange={this.handlemin_Amt} placeholder="formate: 14820" autoComplete="nope"/>
  </div>
<div className="formW">
 <TextField type="number" label="min Qty" name="this.state.min_Qty" onChange={this.handlemin_Qty} placeholder="formate: 26" autoComplete="nope"/>
  </div>
  <div className="formW intop">
 <TextField type="time" label="open Time" name="this.state.open_Time"  onChange={this.handleopen_Time} placeholder="formate: 10:00AM" autoComplete="nope"/>
  </div>
  <div className="formW intop">
  <TextField type="time" label="close Time" name="this.state.close_Time" onChange={this.handleclose_Time} placeholder="formate: 17:00PM" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="number" label="rating" name="this.state.rating"   inputProps={{step: "0.1", lang:"en-US"}} placeholder="formate: 5"  onChange={this.handlerating} autoComplete="nope"/>
  </div>
  <div className="formW">
   <TextField type="text" label="industry" name="this.state.industry" onChange={this.handleindustry} placeholder="formate: Cement" autoComplete="nope"/>
  </div>
  <div className="formW">
   <TextField type="text" label="exchangetobe Listed" name="this.state.exchangetobe_Listed" onChange={this.handleexchangetobe_Listed} placeholder="formate: NSE, BSE" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="max Investment" name="this.state.max_Investment" onChange={this.handlemax_Investment} placeholder="formate: 192660" autoComplete="nope"/>
  </div>
  <div className="formW">
 <TextField type="text" label="fresh Issue" name="this.state.fresh_Issue" onChange={this.handlefresh_Issue} placeholder="formate: 1500 cr" autoComplete="nope"/>
  </div>
  <div className="formW">
  <TextField type="text" label="offer For Sale" name="this.state.offer_For_Sale" onChange={this.handleoffer_For_Sale} placeholder="formate: 570" autoComplete="nope"/>
  </div>
  <div className="dNone">
  <TextField type="hidden"  label="financial Pdf" name="this.state.financial_Pdf" onChange={this.handlefinancial_Pdf} />
  <TextField type="hidden" label="rhp" name="this.state.rhp"  onChange={this.handlerhp} />
  <TextField type="hidden" label="logo" name="this.state.logo" value="" onChange={this.handlelogo} />
  </div>
  {/* <div className="formW">
<TextField type="text" label="about Company" name="this.state.about_Company" onChange={this.handleabout_Company} />
  </div> */}




  <div className="formW positin_p"> 
  <FormControl required className="des">
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={this.handledesignation}
        >
          {/* <MenuItem aria-label="None" value="" >select </MenuItem> */}
                  <MenuItem value="MD">MD</MenuItem>
                  <MenuItem value="CEO">CEO</MenuItem>
                  <MenuItem value="MD & CEO">MD & CEO</MenuItem>
                  <MenuItem value="Founder">Founder</MenuItem>
                  <MenuItem value="Founder & CEO">Founder & CEO</MenuItem>
                  <MenuItem value="Chairman">Chairman</MenuItem>
                  <MenuItem value="Chairman & MD">Chairman & MD</MenuItem>
                  <MenuItem value="Promoter">Promoter</MenuItem>

        </Select>
      </FormControl>
  {/* <FormControl required className="des">
                <InputLabel>designation</InputLabel>

                <Select
                  native
                  // value={this.state.designation}
                  onChange={this.handledesignation}
                  name="buySellIndicator"
                >
                  <option aria-label="None" value="" />
                  <option value="MD">MD</option>
                  <option value="CEO">CEO</option>
                  <option value="MD & CEO">MD & CEO</option>
                  <option value="Founder">Founder</option>
                  <option value="Founder & CEO">Founder & CEO</option>
                  <option value="Chairman">Chairman</option>
                  <option value="Chairman & MD">Chairman & MD</option>
                  <option value="Promoter">Promoter</option>
                </Select>
              </FormControl> */}
   <TextField type="text" className="name" label="Name" name="this.state.founder" onChange={this.handlefounder} />
  </div>

  <div className="formW about_Text">
  <label className="MuiFormLabel-root MuiInputLabel-root  MuiInputLabel-animated" data-shrink="false">about Company</label>
<TextareaAutosize aria-label="about Company" onChange={this.handleabout_Company} />
  </div>


<div className="formW logoUp">
    <label>Upload Logo</label>
    <TradeImportComponent {...callbacks} />
</div>

<div className="formW logoUp">
    <label>Upload RHP PDF</label>
    <RHPImportComponent {...callbacksRHP} />
</div>

<div className="formW logoUp">
    <label>Upload Financial PDF</label>
    <FinancialImportComponent {...callbacksFinancialI} />
</div>


<div className="formW about_Text">
<h4>Field for web ipo</h4>
</div>

<div className="formW">
  <TextField type="text" label="Employee Discount" name="this.state.employeeDiscountWebsite" onChange={this.handleemployeeDiscountWebsite} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="recommendation" name="this.state.recommendationWebsite" onChange={this.handlerecommendationWebsite} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="offer For Sale Website" name="this.state.offerForSaleWebsite" onChange={this.handleofferForSaleWebsite} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="fresh Issue" name="this.state.freshIssueWebsite" onChange={this.handlefreshIssueWebsite} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="peer Comparison" name="this.state.peerComparison" onChange={this.handlepeerComparison} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="retail Allocation" name="this.state.retailAllocationWebsite" onChange={this.handleretailAllocationWebsite} autoComplete="nope"/>
</div>
<div className="formW">
  <TextField type="text" label="promoter Of The Company" name="this.state.promoterOfTheCompany" onChange={this.handlepromoterOfTheCompany} autoComplete="nope"/>
</div>
<div className="formW about_Text">
            <label className="MuiFormLabel-root MuiInputLabel-root  MuiInputLabel-animated">outstanding Litigation Website</label>
               <Editor
                  init={{
                    height: 250,
                    menubar: false,
                    forced_root_block:"",
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    content_style: 'body { font-family:Open Sans; font-size:11px;font-color;#A3ABBC }',
                    toolbar: 'undo redo | ' +
                      'bullist numlist outdent indent | '
                  }}

                  onEditorChange={this.handleoutstandingLitigationWebsite}
                />

</div>
<div className="formW about_Text">
            <label className="MuiFormLabel-root MuiInputLabel-root  MuiInputLabel-animated">financial Statement Website</label>
               <Editor
                  init={{
                    height: 250,
                    menubar: false,
                    forced_root_block:"",
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    content_style: 'body { font-family:Open Sans; font-size:11px;font-color;#A3ABBC }',
                    toolbar: 'undo redo | ' +
                      'bullist numlist outdent indent | '
                  }}

                  onEditorChange={this.handlefinancialStatementWebsite}
                />

</div>


  {/* <div>file<TradeImportComponent/></div> */}
  <br/><br/><br/><br/>
  {/* <button type="submit" color="primary">Add</button> */}
  <div className="form-btn formW">
  <Button
                  size="small"
                  variant="contained"
                  className=""
                  color="primary"
                  type="submit"
                  disableElevation
                >
                  Add IPO
                </Button>
                </div>
                
</form>
            </ExpansionPanelDetails>


          </ExpansionPanel>
        </div>
        {/* <FaqPage/> */}
<AgentMaster/>



      </Grid>
      </div>
      </div>





    )
  }
}
export default ipo;
