import * as React from "react";
import loader from "../../assets/images/loader.gif";

class Loader extends React.Component {
  render() {
    return (
      <div className="loading">
        <img src={loader} alt="loader" />
      </div>
    );
  }
}
export default Loader;
