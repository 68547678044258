import React from "react";
import { Route, Switch } from "react-router-dom";
import LogInComponent from "./component/Auth/LoginComponent";
// import BowLogInComponent from "./component/Auth/BowLoginCompon";

import LogOutComponent from "./component/Auth/LogOutComponent";
import UserListsComponent from "./component/User/UserListsComponent";
import SettlementLIst from "./component/User/SettlementLIst";
import shareLink from "./component/Share/shareLink";
import ipo from "./component/Ipo/ipo";
import holiday from "./component/holidayCalendar/holiday";
import UserDataComponent from "./component/User/AddUser/UserDataComponent";
import { PrivateRoute } from "./protected.route";
import NotFound from "./component/Utils/NotFound";
const routes = (
  <Switch>
    <Route exact path="/login" component={LogInComponent}></Route>
    {/* <Route exact path="/bowlogin" component={BowLogInComponent}></Route> */}
    <PrivateRoute exact path="/" component={UserListsComponent}></PrivateRoute>
    <PrivateRoute exact path="/Ipo-add" component={ipo}></PrivateRoute>
    <PrivateRoute exact path="/Holiday-Calendar" component={holiday}></PrivateRoute>
    <PrivateRoute exact path="/SettlementLIst" component={SettlementLIst}></PrivateRoute>
    <PrivateRoute exact path="/shareLink" component={shareLink}></PrivateRoute>
    <Route exact path="/logout" component={LogOutComponent} />
    <PrivateRoute exact path="*" component={NotFound} />
  </Switch>
);

export default routes;
