import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddResearchCallComponent from "./AddResearchCallComponet";
import { Grid, Button } from "@material-ui/core/";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import UserListUseCaseHanlder from "../../usecase/UserListUseCaseHandler";
import UserListTable from "./UserListTable";

class UserListsComponent extends Component<any, any> {
  state = {
    userMetaInfo: {
      addCall: "",
    },
  };

  componentDidMount() {
    // let username = localStorage.getItem("username");
    // if (!username) {
    //   this.props.history.push("/");
    // }
    this.props.userListData();
    let username = localStorage.getItem("username");
    let userMetaInfo: any = localStorage.getItem("data");
    let updatedMetaInfo = JSON.parse(userMetaInfo);
    // alert(userMetaInfo);
    this.setState({
      userMetaInfo: updatedMetaInfo,
    });
  }

  render() {
    return (
      <div className="CoomonT mainCt">
  <div className="innerprofilesection">
      <Grid className="CoomonT">
        <div className="clapse">
          <ExpansionPanel className="newUiupadte" defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {this.state.userMetaInfo &&
              this.state.userMetaInfo.addCall === "Y" ? (
                <Button
                  size="small"
                  variant="contained"
                  className="changeBTN"
                  color="primary"
                  disableElevation
                >
                  Add Research Call
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  className="changeBTN"
                  color="primary"
                  disabled
                >
                  Add Research Call
                </Button>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <AddResearchCallComponent />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <UserListTable />
      </Grid>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { fetchingUserList, fetchedUserList, allData } = state.userListsReducer;
  return {
    fetchingUserList,
    fetchedUserList,
    allData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    userListData: () =>
      dispatch(UserListUseCaseHanlder.getReasearchCallsData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserListsComponent);
