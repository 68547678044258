// It will impliment the userListUseCase Interface here..
// export UserListUseCaseHanlder implements UserListUseCase;

import { UserListUseCase } from "../adapter/usecase/UserListsUseCase";

import ApiService from "../api/ApiService";
class UserListUseCaseHanlder implements UserListUseCase {
  getReasearchCallsData() {
    return (dispatch) => {
      dispatch({ type: "FETCH_USER_LIST_DATA" });

      ApiService.fetchAllData()
        .then((response) => {
          dispatch({
            type: "FETCH_USER_LIST_DATA_SUCCESS",
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "FETCH_USER_LIST_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }




  addResearchCallData(data: any) {
    return (dispatch) => {
      dispatch({ type: "ADD_USER_DATA" });
      ApiService.saveScripData(data)
        .then((response) => {
          if (response.data.statusCode === 200) {
            dispatch({
              type: "ADD_USER_DATA_SUCCESS",
              payload: response.data,
            });
          } else {
            dispatch({
              type: "ADD_USER_DATA_ERROR",
              payload: response.data.message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: "ADD_USER_DATA_ERROR",
            payload: "Something Went Wrong",
          });
        });
    };
  }

  modifyResearchCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "MODIFY_USER_DATA" });
      ApiService.modifyScripData(data)
        .then((response) => {
          dispatch({
            type: "MODIFY_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "MODIFY_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

// ipo
getApiData() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllDataIPO()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data.response.respList,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}

  modifyIPOCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "MODIFY_IPO_DATA" });
      ApiService.modifyIPOData(data)
        .then((response) => {
          dispatch({
            type: "MODIFY_IPO_DATA_SUCCESS",
            payload: response.data.response,
          });
        })
        .catch((error) => {
          dispatch({
            type: "MODIFY_IPO_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }
// ipo





// holiday calender
getHolidayData() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllDataHoliday()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}

  modifyHolidayCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "MODIFY_IPO_DATA" });
      ApiService.modifyHolidayData(data)
        .then((response) => {
          dispatch({
            type: "MODIFY_IPO_DATA_SUCCESS",
            payload: response.data.response,
          });
        })
        .catch((error) => {
          dispatch({
            type: "MODIFY_IPO_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }
// holiday calender






// settlement
getApiDataSettlement() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllDataIPOSettlement()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}

  modifyIPOCallSettlement(data: any) {
    return (dispatch) => {
      dispatch({ type: "MODIFY_SETTLEMENT_DATA" });
      ApiService.modifyIPODataSettlement(data)
        .then((response) => {
          dispatch({
            type: "MODIFY_SETTLEMENT_SUCCESS",
            payload: response.data.response,
          });
        })
        .catch((error) => {
          dispatch({
            type: "MODIFY_SETTLEMENT_ERROR",
            payload: error.response,
          });
        });
    };
  }
// settlement







  closeResearchCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "CLOSE_USER_DATA" });
      ApiService.closeScripData(data)
        .then((response) => {
          dispatch({
            type: "CLOSE_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "CLOSE_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  publishUserCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "PUBLISH_USER_DATA" });
      ApiService.publishScripData(data)
        .then((response) => {
          dispatch({
            type: "PUBLISH_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "PUBLISH_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  falseShowMessage() {
    return (dispatch) => {
      dispatch({
        type: "FALSE_SHOW_MESSAGE",
        payload: false,
      });
    };
  }
}
export default new UserListUseCaseHanlder();
