import React, { useEffect, useState, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../DataTable";
import UserListUseCaseHanlder from "../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../Utils/Loader";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Publish";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import CachedIcon from "@material-ui/icons/Cached";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import {
  Select,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core/";
import ApiService from "../../api/ApiService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const UserListTable = (props) => {
  const classes = useStyles();

  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editaBtnClicked, setEditaBtnClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);
  const [publishModal, setPublishModal] = React.useState(false);
  const [fetchingLatestCMP, setfetchingLatestCMP] = React.useState(false);
  const [notification, setNofication] = React.useState(true);
  const handlePublishChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  // const handleModifyChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNofication(event.target.checked);
  // };
  const handleCloseChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  const [
    fairPriceValidationMessage,
    setFairPriceValidationMessage,
  ] = React.useState("");

  const [
    expectedReturnInDurationError,
    setExpectedReturnInDurationError,
  ] = React.useState("");

  const [
    expectedReturnInPercentageError,
    setExpectedReturnInPercentageError,
  ] = React.useState("");

  const [closeData, setCloseData] = useState({
    id: "",
    actions: "",
    entryPrice: "",
    buySellIndicator: "",
    targetPrice: "",
    category: "",
    categoriesId: "",
    symbol: "",
    stopLossPrice: "",
  });

  const [publishData, setPublishData] = useState({
    id: "",
    actions: "",
    entryPrice: "",
    buySellIndicator: "",
    targetPrice: "",
    category: "",
    categoriesId: "",
    symbol: "",
    stopLossPrice: "",
  });

  const [editableData, setEditableData] = useState({
    id: "",
    category: "",
    categoriesId: "",
    symbol: "",
    exchange: "",
    currentMarketPrice: "",
    buySellIndicator: "",
    entryPrice: "",
    targetPrice: "",
    expectedReturnInPercentage: "",
    expectedReturnInDuration: "",
    returnIn: "",
    stopLossPrice: "",
    fairPrice: "",
    scripId: "",
    actions: "M",
  });

  const [userMetaInfo, setUserMetaInfo] = useState({
    addCall: "",
    adminRollType: "",
    closedCall: "",
    createdAt: "",
    modifyCall: "",
    publishCall: "",
  });

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setCloseModal(false);
  };
  const handlePublishModal = () => {
    setPublishModal(false);
  };
  const handleClose = () => {
    setOpen(false);
    setFairPriceValidationMessage("");
    setExpectedReturnInDurationError("");
    setExpectedReturnInPercentageError("");
    setNofication(true);
  };

  const onHandlerKeyUpExp = (event) => {
    // alert("test");
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInPercentageError("Decimal value not allowed");
          } else {
            setExpectedReturnInPercentageError("");
          }
        }
      }
    }
  };

  const onHandlerKeyUpReturnInDuration = (event) => {
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInDurationError("Decimal value not allowed");
          } else {
            setExpectedReturnInDurationError("");
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getReasearchCallsData());
  }, []);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
    let userMetaInfo: any = localStorage.getItem("data");
    let updatedMetaInfo: any = JSON.parse(userMetaInfo);
    setUserMetaInfo(updatedMetaInfo);
  }, []);

  // const editUser = (data: any) => {
  //   // alert(JSON.stringify(data));
  //   setOpen(true);
  //   setEditaBtnClicked(true);
  //   setEditableData(data);
  // };

  const handleChange = (e: any) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
    //  console.log(e.target.value);
  };
  const onHandlerKeyUpEntryExpPrice = (e: any) => {
    //console.log(e.target.name, e.target.value);

    if (editableData.entryPrice !== "" && editableData.targetPrice !== "") {
      let targetPrice = parseInt(editableData.targetPrice);
      let entryPrice = parseInt(editableData.entryPrice);
      let expReturninPer = Math.abs(
        ((targetPrice - entryPrice) / entryPrice) * 100
      ).toFixed(2);
      setEditableData({
        ...editableData,
        expectedReturnInPercentage: expReturninPer,
      });
    } else if (
      editableData.entryPrice === "" &&
      editableData.targetPrice === ""
    ) {
      setEditableData({
        ...editableData,
        expectedReturnInPercentage: "",
      });
    }
  };

  const onHandlerKeyUpTargetExpPrice = (e: any) => {
    console.log(e.target.name, e.target.value);
    if (editableData.entryPrice !== "" && editableData.targetPrice !== "") {
      let targetPrice = parseInt(editableData.targetPrice);
      let entryPrice = parseInt(editableData.entryPrice);
      let expReturninPer = Math.abs(
        ((targetPrice - entryPrice) / entryPrice) * 100
      ).toFixed(2);
      setEditableData({
        ...editableData,
        expectedReturnInPercentage: expReturninPer,
      });
    } else if (
      editableData.entryPrice === "" &&
      editableData.targetPrice === ""
    ) {
      setEditableData({
        ...editableData,
        expectedReturnInPercentage: "",
      });
    }
  };

  const handleKeyUp = (event) => {
    if (event.target.value && editableData.currentMarketPrice) {
      if (
        parseInt(event.target.value) > 0 &&
        parseInt(event.target.value) < parseInt(editableData.currentMarketPrice)
      ) {
        setFairPriceValidationMessage("It Must be greater than CMP");
      } else if (
        parseInt(event.target.value) > parseInt(editableData.currentMarketPrice)
      ) {
        setFairPriceValidationMessage("");
      }
    }
  };

  const handleKeyUpCMP = (event) => {
    if (event.target.value && editableData.fairPrice) {
      if (
        parseInt(event.target.value) > 0 &&
        parseInt(event.target.value) < parseInt(editableData.fairPrice)
      ) {
        setFairPriceValidationMessage("");
      } else if (
        parseInt(event.target.value) > parseInt(editableData.fairPrice)
      ) {
        setFairPriceValidationMessage("It Must be greater than CMP");
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // alert(editableData);
    let data = {
      id: editableData.id,
      symbol: editableData.symbol,
      scripId: editableData.scripId,
      buySellIndicator: editableData.buySellIndicator,
      exchange: editableData.exchange,
      netChangeIndicator: "+",
      changeInPrice: 0,
      changeInPercentage: 0,
      currentMarketPrice: parseInt(editableData.currentMarketPrice),
      expectedReturnInPercentage: editableData.expectedReturnInPercentage,
      expectedReturnInDuration: editableData.expectedReturnInDuration,
      entryPrice: editableData.entryPrice,
      targetPrice: editableData.targetPrice,
      stopLossPrice: editableData.stopLossPrice,
      fairPrice: editableData.fairPrice,
      categoriesId: editableData.categoriesId,
      image: "",
      isActive: true,
      returnIn: editableData.returnIn,
      actions: "M",
      notificationFlag: false,
    };
    console.log(data, "modified data");
    if (
      (parseInt(data.fairPrice) === 0 &&
        fairPriceValidationMessage === "" &&
        expectedReturnInDurationError === "" &&
        expectedReturnInPercentageError === "") ||
      (parseInt(data.fairPrice) !== 0 &&
        fairPriceValidationMessage === "" &&
        parseInt(editableData.fairPrice) >
          parseInt(editableData.currentMarketPrice) &&
        expectedReturnInDurationError === "" &&
        expectedReturnInPercentageError === "")
    ) {
      modifyCall(data);
      setEditableData({
        id: "",
        category: "",
        categoriesId: "",
        symbol: "",
        buySellIndicator: "",
        exchange: "",
        currentMarketPrice: "",
        entryPrice: "",
        targetPrice: "",
        expectedReturnInPercentage: "",
        expectedReturnInDuration: "",
        returnIn: "",
        stopLossPrice: "",
        fairPrice: "",
        scripId: "",
        actions: "",
      });
      setNofication(true);
    }
  };

  const modifyCall = (data: any) => {
    dispatch(UserListUseCaseHanlder.modifyResearchCall(data));
    setOpen(false);
  };

  const getCMPData = () => {
    let bowSessionID = localStorage.getItem("bowSessionID");
    if (bowSessionID !== null) {
      bowSessionID = bowSessionID.slice(1, -1);
    }
    let cmpBody = {
      scripTokenNumber: editableData.scripId,
      exchangeId: editableData.exchange === "BSE" ? 1 : 2,
      sessionKey: bowSessionID,
    };
    setfetchingLatestCMP(true);
    ApiService.cmpData(cmpBody).then((res) => {
      if (res.data.statusCode === 200) {
        setfetchingLatestCMP(false);
        setEditableData({
          ...editableData,
          currentMarketPrice: res.data.body.MarketDepthData[0].LastTradePrice,
        });
      }
    });
  };
  // const closeUser = (data: any) => {
  //   //  setOpen(true);
  //   let updateData = {
  //     id: data.id,
  //     actions: "C",
  //   };
  //   dispatch(UserListUseCaseHanlder.closeResearchCall(updateData));
  //   setCloseModal(false);
  // };

  // const publishUser = (data: any) => {
  //   let updateData: any = {
  //     id: data.id,
  //     actions: "P",
  //   };
  //   dispatch(UserListUseCaseHanlder.publishUserCall(updateData));
  // };

  const headers = [
    { name: "Category", field: "category", sortable: true },
    { name: "Exchange", field: "exchange", sortable: true },
    { name: "Scrip", field: "symbol", sortable: true },
    { name: "B/S Indicator", field: "buySellIndicator", sortable: true },
    { name: "CMP", field: "currentMarketPrice", sortable: true },

    { name: "Entry Price", field: "entryPrice", sortable: true },
    { name: "Target Price", field: "targetPrice", sortable: true },
    { name: "Stop Loss Price", field: "stopLossPrice", sortable: true },
    { name: "Fair Price", field: "fairPrice", sortable: true },
    {
      name: "Exp Return in %",
      field: "expectedReturnInPercentage",
      sortable: true,
    },
    {
      name: "Return Time",
      field: "expectedReturnInDuration",
      sortable: true,
    },
    { name: "Return In", field: "returnIn", sortable: true },

    { name: "Date/Time", field: "scripCreationTime", sortable: true },
    { name: "Status", field: "status", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
            data.category.toLowerCase().includes(search.toLowerCase()) ||
            data.exchange.toLowerCase().includes(search.toLowerCase()) ||
            data.symbol.toLowerCase().includes(search.toLowerCase()) ||
            data.buySellIndicator
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.currentMarketPrice
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.entryPrice
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.targetPrice
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.stopLossPrice
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            String(data.fairPrice)
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.expectedReturnInPercentage
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.expectedReturnInDuration
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.scripCreationTime
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.fairPrice
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.returnIn.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);

  return (
    <>
     <div className="searchUpdatesectin">
      <Search
        onSearch={(value) => {
          setSearch(value);
          setCurrentPage(1);
        }}
      />
</div>
      <Paper className="tableP">
        <Grid container className="listContainer newUiupadte">
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <TableBody>
              {fetchedUserList ? (
                datasData.map((data: any) => (
                  <TableRow id={data.id} key={data.id} className={data.actions}>
                    <TableCell align="left">{data.category}</TableCell>
                    <TableCell align="left">{data.exchange}</TableCell>
                    <TableCell align="left">{data.symbol}</TableCell>
                    {data.buySellIndicator === "B" ? (
                      <TableCell align="left">Buy</TableCell>
                    ) : null}
                    {data.buySellIndicator === "S" ? (
                      <TableCell align="left">Sell</TableCell>
                    ) : null}

                    <TableCell align="left">
                      {data.currentMarketPrice}
                    </TableCell>

                    <TableCell align="left">{data.entryPrice}</TableCell>
                    <TableCell align="left">{data.targetPrice}</TableCell>
                    <TableCell align="left">{data.stopLossPrice}</TableCell>
                    <TableCell align="left">
                      {data.fairPrice === 0 ? null : data.fairPrice}
                    </TableCell>
                    <TableCell align="left">
                      {data.expectedReturnInPercentage}
                    </TableCell>
                    <TableCell align="left">
                      {data.expectedReturnInDuration}
                    </TableCell>
                    <TableCell align="left">{data.returnIn} </TableCell>
                    <TableCell align="left">{data.scripCreationTime}</TableCell>
                    <TableCell align="left">
                      {data.actions === "I" ? "New" : null}
                      {data.actions === "C" ? "Closed" : null}
                      {data.actions === "P" ? "Published" : null}
                      {data.actions === "M" ? "Modified" : null}
                    </TableCell>

                    <TableCell align="left" className="actionBTN">
                      {userMetaInfo && userMetaInfo.modifyCall === "Y" ? (
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setOpen(true);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit">
                          <IconButton
                            disabled
                            aria-label="edit"
                            onClick={() => {
                              setOpen(true);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {userMetaInfo && userMetaInfo.closedCall === "Y" ? (
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              setCloseModal(true);
                              setCloseData(data);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Close">
                          <IconButton
                            disabled
                            aria-label="close"
                            onClick={() => {
                              setCloseModal(true);
                              setCloseData(data);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {userMetaInfo && userMetaInfo.publishCall === "Y" ? (
                        <Tooltip title="Publish">
                          <IconButton
                            aria-label="publish"
                            onClick={() => {
                              console.log(data);
                              setPublishModal(true);
                              setPublishData(data);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <PublishIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Publish">
                          <IconButton
                            
                            aria-label="Publish"
                            onClick={() => {
                              setPublishModal(true);
                              setPublishData(data);
                            }}
                          >
                            <PublishIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <span className="loader-body">
                  <Loader />
                </span>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span>Do you want to Modify the call ? </span>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              <CloseIcon />
            </Button>
          </DialogActions>
        </DialogTitle>

        <DialogContent>
          <Grid container className="addDatasection">
            {editaBtnClicked && editableData ? (
              <form
                style={formContainer}
                onSubmit={handleSubmit}
                id="modifyResearch"
              >
                <div className="formW">
                  <TextField
                    label="Category"
                    fullWidth
                    margin="normal"
                    name="category"
                    value={editableData.category}
                    disabled
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    fullWidth
                    id="query"
                    label="Enter Scrip"
                    type="text"
                    name="symbol"
                    autoComplete="off"
                    required
                    value={editableData.symbol}
                  />
                </div>
                <div className="formW">
                  <TextField
                    label="Exchange"
                    fullWidth
                    margin="normal"
                    name="exchange"
                    value={editableData.exchange}
                    disabled
                  />
                </div>
                <div className="formW">
                  {editableData.buySellIndicator === "B" ? (
                    <TextField
                      disabled
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Buy"
                      required
                    />
                  ) : null}
                  {editableData.buySellIndicator === "S" ? (
                    <TextField
                      disabled
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Sell"
                      required
                    />
                  ) : null}
                </div>
                <div className="formW">
                  <TextField
                    label="CMP"
                    fullWidth
                    margin="normal"
                    name="currentMarketPrice"
                    type="number"
                    onChange={handleChange}
                    onKeyUp={handleKeyUpCMP}
                    value={editableData.currentMarketPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                  {editableData.currentMarketPrice !== "" &&
                  editableData.exchange !== "" &&
                  editableData.scripId !== "" ? (
                    <span
                      className={
                        fetchingLatestCMP ? "refreshIcon green" : "refreshIcon"
                      }
                      onClick={getCMPData}
                    >
                      <Tooltip title="Refresh CMP">
                        <IconButton aria-label="Refresh CMP">
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  ) : null}
                </div>

                <div className="formW">
                  <TextField
                    type="number"
                    label=" Entry Price"
                    fullWidth
                    margin="normal"
                    name="entryPrice"
                    onChange={handleChange}
                    onKeyUp={onHandlerKeyUpEntryExpPrice}
                    value={editableData.entryPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    type="number"
                    label="Target Price"
                    fullWidth
                    margin="normal"
                    name="targetPrice"
                    onChange={handleChange}
                    onKeyUp={onHandlerKeyUpTargetExpPrice}
                    value={editableData.targetPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    type="number"
                    label="Stop Loss"
                    fullWidth
                    margin="normal"
                    name="stopLossPrice"
                    onChange={handleChange}
                    value={editableData.stopLossPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                {editableData.category === "IT Sector Stocks" ? (
                  <div className="formW">
                    <TextField
                      required
                      label="Fair Price"
                      fullWidth
                      margin="normal"
                      name="fairPrice"
                      type="number"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      value={editableData.fairPrice}
                      inputProps={{ min: "1", step: "any" }}
                      helperText={fairPriceValidationMessage}
                    />
                  </div>
                ) : null}
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Exp. Return In %"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInPercentage"
                    onChange={handleChange}
                    value={editableData.expectedReturnInPercentage}
                    required
                    onKeyUp={onHandlerKeyUpExp}
                    helperText={expectedReturnInPercentageError}
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    type="number"
                    label="Return Time"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInDuration"
                    onChange={handleChange}
                    value={editableData.expectedReturnInDuration}
                    required
                    onKeyUp={onHandlerKeyUpReturnInDuration}
                    helperText={expectedReturnInDurationError}
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <FormControl required fullWidth>
                    <InputLabel>Return In</InputLabel>
                    <Select
                      native
                      value={editableData.returnIn}
                      onChange={handleChange}
                      name="returnIn"
                    >
                      <option aria-label="None" value="" />
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                      <option value="months">Months</option>
                      <option value="years">Years</option>
                    </Select>
                  </FormControl>
                </div>

                <br />
                {/* {editableData.actions === "P" ? (
                  <div style={{ width: "100%" }}>
                    <Grid container className="notifyBox ">
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Entry Price modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Stop Loss Price modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Target Price modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Expected percentage modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Return In modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" color="primary" />}
                          label="Time Frame Price modified"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextareaAutosize
                          aria-label="Other Remarks"
                          rowsMin={3}
                          placeholder="Other Remarks"
                        />
                      </Grid>
                    </Grid>
                  </div>
                ) : null} */}
                <div style={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    value="submit"
                    disableElevation
                  >
                    Yes
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </div>
              </form>
            ) : null}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={closeModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              setCloseModal(false);
            }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>

        <DialogTitle id="alert-dialog-title">
          Do you want to Close the call?
        </DialogTitle>
        <DialogContent>
          <Grid container className="addDatasection">
            {editaBtnClicked ? (
              <form style={formContainer} id="modifyResearch">
                <div className="formW">
                  <TextField
                    label="Category"
                    fullWidth
                    margin="normal"
                    name="category"
                    value={editableData.category}
                    disabled
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    fullWidth
                    id="query"
                    label="Enter Scrip"
                    type="text"
                    name="symbol"
                    autoComplete="off"
                    required
                    value={editableData.symbol}
                  />
                </div>
                <div className="formW">
                  <TextField
                    label="Exchange"
                    fullWidth
                    margin="normal"
                    name="exchange"
                    value={editableData.exchange}
                    disabled
                  />
                </div>

                <div className="formW">
                  {editableData.buySellIndicator === "B" ? (
                    <TextField
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Buy"
                      required
                      disabled
                    />
                  ) : null}
                  {editableData.buySellIndicator === "S" ? (
                    <TextField
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Sell"
                      required
                      disabled
                    />
                  ) : null}
                </div>

                <div className="formW">
                  <TextField
                    disabled
                    label="CMP"
                    fullWidth
                    margin="normal"
                    name="currentMarketPrice"
                    type="number"
                    onChange={handleChange}
                    onKeyUp={handleKeyUpCMP}
                    value={editableData.currentMarketPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>

                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label=" Entry Price"
                    fullWidth
                    margin="normal"
                    name="entryPrice"
                    onChange={handleChange}
                    value={editableData.entryPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Target Price"
                    fullWidth
                    margin="normal"
                    name="targetPrice"
                    onChange={handleChange}
                    value={editableData.targetPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Stop Loss"
                    fullWidth
                    margin="normal"
                    name="stopLossPrice"
                    onChange={handleChange}
                    value={editableData.stopLossPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                {editableData.category === "IT Sector Stocks" ? (
                  <div className="formW">
                    <TextField
                      disabled
                      required
                      label="Fair Price"
                      fullWidth
                      margin="normal"
                      name="fairPrice"
                      type="number"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      value={editableData.fairPrice}
                      inputProps={{ min: "1", step: "any" }}
                      helperText={fairPriceValidationMessage}
                    />
                  </div>
                ) : null}
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Exp. Return In %"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInPercentage"
                    onChange={handleChange}
                    value={editableData.expectedReturnInPercentage}
                    required
                    onKeyUp={onHandlerKeyUpExp}
                    helperText={expectedReturnInPercentageError}
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Return Time"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInDuration"
                    onChange={handleChange}
                    value={editableData.expectedReturnInDuration}
                    required
                    onKeyUp={onHandlerKeyUpReturnInDuration}
                    helperText={expectedReturnInDurationError}
                  />
                </div>
                <div className="formW">
                  <FormControl required fullWidth disabled>
                    <InputLabel>Return In</InputLabel>
                    <Select
                      native
                      value={editableData.returnIn}
                      onChange={handleChange}
                      name="returnIn"
                    >
                      <option aria-label="None" value="" />
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                      <option value="months">Months</option>
                      <option value="years">Years</option>
                    </Select>
                  </FormControl>
                </div>
              </form>
            ) : null}
          </Grid>

          {editableData.actions === "P" ? (
            <>
              <div className="formW">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCloseChecked}
                      name="checkedB"
                      color="primary"
                      checked={notification}
                    />
                  }
                  label="Notify client through push notification"
                />
              </div>
              <br />
            </>
          ) : null}
          {editableData.actions === "P" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log({
                  id: closeData.id,
                  actions: "C",
                  notificationFlag: notification,
                  entryPrice: closeData.entryPrice,
                  buySellIndicator: closeData.buySellIndicator,
                  targetPrice: closeData.targetPrice,
                  category: closeData.category,
                  categoriesId: closeData.categoriesId,
                  symbol: closeData.symbol,
                  stopLossPrice: closeData.stopLossPrice,
                });
                dispatch(
                  UserListUseCaseHanlder.closeResearchCall({
                    id: closeData.id,
                    actions: "C",
                    notificationFlag: notification,
                    entryPrice: closeData.entryPrice,
                    buySellIndicator: closeData.buySellIndicator,
                    targetPrice: closeData.targetPrice,
                    category: closeData.category,
                    categoriesId: closeData.categoriesId,
                    symbol: closeData.symbol,
                    stopLossPrice: closeData.stopLossPrice,
                  })
                );

                setCloseModal(false);
                setNofication(true);
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(
                  UserListUseCaseHanlder.closeResearchCall({
                    id: closeData.id,
                    actions: "C",
                    notificationFlag: false,
                  })
                );

                setCloseModal(false);
                setNofication(true);
              }}
            >
              Yes
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(closeData);
              setCloseModal(false);
              setNofication(true);
            }}
          >
            No
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={publishModal}
        onClose={handlePublishModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              setPublishModal(false);
            }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>

        <DialogTitle id="alert-dialog-title">
          Do you want to Publish the call?
        </DialogTitle>
        <DialogContent>
          <Grid container className="addDatasection">
            {editaBtnClicked ? (
              <form style={formContainer} id="modifyResearch">
                <div className="formW">
                  <TextField
                    label="Category"
                    fullWidth
                    margin="normal"
                    name="category"
                    value={editableData.category}
                    disabled
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    fullWidth
                    id="query"
                    label="Enter Scrip"
                    type="text"
                    name="symbol"
                    autoComplete="off"
                    required
                    value={editableData.symbol}
                  />
                </div>
                <div className="formW">
                  <TextField
                    label="Exchange"
                    fullWidth
                    margin="normal"
                    name="exchange"
                    value={editableData.exchange}
                    disabled
                  />
                </div>
                <div className="formW">
                  {editableData.buySellIndicator === "B" ? (
                    <TextField
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Buy"
                      required
                      disabled
                    />
                  ) : null}

                  {editableData.buySellIndicator === "S" ? (
                    <TextField
                      type="text"
                      label="B/S Indicator"
                      fullWidth
                      margin="normal"
                      name="buySellIndicator"
                      onChange={handleChange}
                      value="Sell"
                      required
                      disabled
                    />
                  ) : null}
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    label="CMP"
                    fullWidth
                    margin="normal"
                    name="currentMarketPrice"
                    type="number"
                    onChange={handleChange}
                    onKeyUp={handleKeyUpCMP}
                    value={editableData.currentMarketPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>

                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label=" Entry Price"
                    fullWidth
                    margin="normal"
                    name="entryPrice"
                    onChange={handleChange}
                    value={editableData.entryPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Target Price"
                    fullWidth
                    margin="normal"
                    name="targetPrice"
                    onChange={handleChange}
                    value={editableData.targetPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Stop Loss"
                    fullWidth
                    margin="normal"
                    name="stopLossPrice"
                    onChange={handleChange}
                    value={editableData.stopLossPrice}
                    required
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                {editableData.category === "IT Sector Stocks" ? (
                  <div className="formW">
                    <TextField
                      disabled
                      required
                      label="Fair Price"
                      fullWidth
                      margin="normal"
                      name="fairPrice"
                      type="number"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      value={editableData.fairPrice}
                      inputProps={{ min: "1", step: "any" }}
                      helperText={fairPriceValidationMessage}
                    />
                  </div>
                ) : null}
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Exp. Return In %"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInPercentage"
                    onChange={handleChange}
                    value={editableData.expectedReturnInPercentage}
                    required
                    onKeyUp={onHandlerKeyUpExp}
                    helperText={expectedReturnInPercentageError}
                    inputProps={{ min: "1", step: "any" }}
                  />
                </div>
                <div className="formW">
                  <TextField
                    disabled
                    type="number"
                    label="Return Time"
                    fullWidth
                    margin="normal"
                    name="expectedReturnInDuration"
                    onChange={handleChange}
                    value={editableData.expectedReturnInDuration}
                    required
                    onKeyUp={onHandlerKeyUpReturnInDuration}
                    helperText={expectedReturnInDurationError}
                  />
                </div>
                <div className="formW">
                  <FormControl required fullWidth disabled>
                    <InputLabel>Return In</InputLabel>
                    <Select
                      native
                      value={editableData.returnIn}
                      onChange={handleChange}
                      name="returnIn"
                    >
                      <option aria-label="None" value="" />
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                      <option value="months">Months</option>
                      <option value="years">Years</option>
                    </Select>
                  </FormControl>
                </div>
              </form>
            ) : null}
          </Grid>
          <div className="formW">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handlePublishChecked}
                  name="checkedB"
                  color="primary"
                  checked={notification}
                />
              }
              label="Notify client through push notification"
            />
          </div>
          <br />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log({
                id: publishData.id,
                actions: "P",
                notificationFlag: notification,
                entryPrice: publishData.entryPrice,
                buySellIndicator: publishData.buySellIndicator,
                targetPrice: publishData.targetPrice,
                category: publishData.category,
                categoriesId: publishData.categoriesId,
                symbol: publishData.symbol,
                stopLossPrice: publishData.stopLossPrice,
              });
              dispatch(
                UserListUseCaseHanlder.publishUserCall({
                  id: publishData.id,
                  actions: "P",
                  notificationFlag: notification,
                  entryPrice: publishData.entryPrice,
                  buySellIndicator: publishData.buySellIndicator,
                  targetPrice: publishData.targetPrice,
                  category: publishData.category,
                  categoriesId: publishData.categoriesId,
                  symbol: publishData.symbol,
                  stopLossPrice: publishData.stopLossPrice,
                })
              );
              setPublishModal(false);
              setNofication(true);
            }}
          >
            Yes
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(editableData.entryPrice);
              setPublishModal(false);
              setNofication(true);
            }}
          >
            No
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

export default UserListTable;
