import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button, MenuItem } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
class shareLink extends React.Component<{}, any>{
  // export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi-uat.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.state = { checked: false };
    this.canvas = React.createRef()
    this.state = {
      leedCode: '',
      ipoName: '',
      sourceCode: '',


    }
  }
  componentDidUpdate() {
    setTimeout(() => this.setState({ errorMessage: '' }), 10000);
    setTimeout(() => this.setState({ sucessMessage: '' }), 10000);
  }




  handleleedCodeChange = event => { this.setState({ leedCode: event.target.value }) }
  handleipoNameChange = event => { this.setState({ ipoName: event.target.value }) }
  handlesourceCode = event => { this.setState({ sourceCode: event.target.value }) }



  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()


    axios.post('https://gatewayapi.invest19.com/user/map/partner/user',
      {
        leedCode: this.state.leedCode,
        ipoName: this.state.ipoName,
        sourceCode: this.state.sourceCode,
      },

    )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you');
                  console.log(res.data);
         alert("Short Link: "+  res.data.shortLink );
         alert("Preview Link: "+  res.data.previewLink );
// alert(JSON.stringify(res.data.shortLink));
       
        this.setState({ sucessMessage: res.data });
      })
      .catch(err => {
        this.setState({ errorMessage: err.message });
      })
  }



  render() {

    return (
      <div className="CoomonT mainCt">
        <div className="innerprofilesection">
          <Grid className="CoomonT">
            <div className="clapse ">
              <ExpansionPanel className="newUiupadte" defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Button
                    size="small"
                    variant="contained"
                    className="changeBTN"
                    color="primary"
                    disableElevation
                  >
                    Add New Link
                  </Button>
                </ExpansionPanelSummary>


                <ExpansionPanelDetails className="inner_ipo_form">
                  {this.state.errorMessage && <Alert severity="error" className="errorMsd">{this.state.errorMessage}</Alert>}
                  <form onSubmit={this.handleSubmit} autoComplete="nope">
                    <div className="formW">
                      <TextField type="number" label="leed Code" name="this.state.leedCode" onChange={this.handleipoNameChange} required/>
                    </div>
                    <div className="formW">
                      <TextField type="text" label="ipo Name" name="this.state.ipoName" onChange={this.handleipoNameChange} required/>
                    </div>
                    <div className="formW">
                      <TextField type="text" label="source Code" name="this.state.sourceCode" onChange={this.handlesourceCode} required/>
                    </div>

                    <div className="form-btn formW">
                      <Button
                        size="small"
                        variant="contained"
                        className=""
                        color="primary"
                        type="submit"
                        disableElevation
                      >
                        Add New Link
                      </Button>
                    </div>

                  </form>
                </ExpansionPanelDetails>


              </ExpansionPanel>
            </div>

          </Grid>
        </div>
      </div>





    )
  }
}
export default shareLink;
