import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button, MenuItem } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import TradeImportComponent from "../Ipo/AddLogo";
import RHPImportComponent from "../Ipo/AddRHP";
import FinancialImportComponent from "../Ipo/AddFinancial";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import HolidayMaster from "../holidayCalendar/HolidayMaster";
import { FormControl } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core/";
import AutoCompleteScrip from "../User/AutoCompleteScrip";
class holiday extends React.Component<{},any>{
// export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi-uat.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.state = {checked: false};
    this.canvas = React.createRef()
    this.state = {
      day: '',
      holidayName: '',
      holidayDate: '',

}
}
  componentDidUpdate(){
    setTimeout(() => this.setState({errorMessage:''}), 40000);
    setTimeout(() => this.setState({sucessMessage:''}), 40000);
  }


  handleSearchScripName = (scripData) => {
    this.setState({
      symbol: scripData.symbol,
      scripId: scripData.scripId,
      exchange: scripData.exchange,
    });
  };
  handleCmpdData = (data) => {
    this.setState({
      currentMarketPrice: data,
    });
  };
  toggleReset = (value) => {
    this.setState({
      resetForm: value,
    });
  };
    // Image Preview Handler
    handleImagePreview = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];

      this.setState({
          image_preview: image_as_base64,
          image_file: image_as_files,
      })
  }

  // Image/File Submit Handler
  handleSubmitFileUpload= () => {

      if (this.state.image_file !== null){

          let formData = new FormData();
          formData.append('ipoName', 'tata');
          formData.append('document', this.state.image_file);

          axios.post(
              this.custom_file_upload_url,
              formData,
              {
                  headers: {
                      // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                      "Content-type": "multipart/form-data",
                  },                    
              }
          )
          .then(res => {
              console.log(`Success` + res.data.response);
              var json= JSON.parse(JSON.stringify(res.data.response));
              console.log(json);
              console.log(json.url);
              console.log(this.canvas.current.value);
              // this.canvas.current.value=json.url;
              // console.log(this.canvas.current.value);
              return json.url;
          })
          .catch(err => {
              console.log(err);
          })
      }
  }



  uploadDocument = (e) => {
    console.log(e); 
  }



  handleDayChange = event => {this.setState({ day: event.target.value })}
  handleHolidayNameChange = event => {this.setState({ holidayName: event.target.value })}
  handleHolidayDateDate = event => {this.setState({ holidayDate: event.target.value })}
  
// handleabout_Company = event => {this.setState({ about_Company: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()

    axios.post('https://gatewayapi.invest19.com/super/add/exchange/holiday',
      { day: this.state.day, 
        holidayName: this.state.holidayName, 
        holidayDate: this.state.holidayDate,
        
      },
        
        )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you')
        this.setState({sucessMessage: res.data});
      })
    .catch(err => { 
      this.setState({errorMessage: err.message});
    })
  }


  onUpload = (url) => {
    console.log('I have been clicked', url);
    this.setState({logo:url})
  }
  onUploadrhp = (url) => {
    console.log('I have been clicked', url);
    this.setState({rhp:url})
  }
  onUploadFinancialI = (url) => {
    console.log('I have been clicked', url);
    this.setState({financial_Pdf:url})
  }

  
  render() {
    
    const callbacks = {upload: this.onUpload};
    const callbacksRHP = {upload: this.onUploadrhp};
    const callbacksFinancialI = {upload: this.onUploadFinancialI};
    return (
<div className="CoomonT mainCt">
  <div className="innerprofilesection">
      <Grid className="CoomonT">
        <div className="clapse ">
          <ExpansionPanel className="newUiupadte">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Button
                  size="small"
                  variant="contained"
                  className="changeBTN"
                  color="primary"
                  disableElevation
                >
                  Add New Holiday
                </Button>
</ExpansionPanelSummary>


<ExpansionPanelDetails className="inner_ipo_form">
{ this.state.errorMessage && <Alert severity="error" className="errorMsd">{ this.state.errorMessage }</Alert>}
<form onSubmit={this.handleSubmit} autoComplete="nope">
<div className="formW intop">
<TextField type="text" label="day"  name="this.state.day" onChange={this.handleDayChange}/>
</div>  
  <div className="formW intop">
    <TextField type="text" label="holidayName" name="this.state.holidayName" onChange={this.handleHolidayNameChange} />
  </div>
  <div className="formW intop">
     <TextField type="date" label="holidayDate" name="this.state.holidayDate" onChange={this.handleHolidayDateDate} />
  </div>
  


  {/* <div>file<TradeImportComponent/></div> */}
  <br/><br/><br/><br/>
  {/* <button type="submit" color="primary">Add</button> */}
  <div className="form-btn formW">
  <Button
                  size="small"
                  variant="contained"
                  className=""
                  color="primary"
                  type="submit"
                  disableElevation
                >
                  Add Holiday
                </Button>
                </div>
                
</form>
            </ExpansionPanelDetails>


          </ExpansionPanel>
        </div>
        {/* <FaqPage/> */}
<HolidayMaster/>



      </Grid>
      </div>
      </div>





    )
  }
}
export default holiday;
