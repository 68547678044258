import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core/";
import { connect } from "react-redux";
import SelectCategoryUseCaseHandler from "../../usecase/SelectCategoryUseCaseHandler";

interface userCategoryProps {
  fetchingCategoryList: boolean;
  fetchedCategoryOnChage: boolean;
  fetchedCategoryList: any;
  resetForm: any;
  toggleReset: any;
}
interface userCategoryState {
  onChangeList: any;
  resetForm: any;
}

class SelectCategoryComponent extends Component<any, any> {
  state: userCategoryState = {
    onChangeList: {
      value: "",
    },
    resetForm: false,
  };

  componentDidMount() {
    this.props.categoryData();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.resetForm && this.state.resetForm === false) {
      this.setState({
        onChangeList: {
          value: "",
        },
        resetForm: true,
      });
    }
    if (!nextProps.resetForm && this.state.resetForm !== false) {
      this.setState({
        resetForm: false,
      });
    }
  }
  handleChange = (e) => {
    this.props.toggleReset(false);
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    var optionId = optionElement.getAttribute("id");

    this.setState({
      onChangeList: {
        id: optionId,
        value: e.target.value,
      },
    });
    this.props.change({
      id: optionId,
      value: e.target.value,
    });
  };

  render() {
    return (
      <FormControl fullWidth required>
        {this.props.fetchedCategoryList ? (
          <>
            <InputLabel>Categories</InputLabel>

            <Select
              native
              className="pp"
              name="category"
              onChange={this.handleChange}
              value={this.state.onChangeList.value}
            >
              <option row-label="None" value="" />
              {this.props.categories.map((item: any, index) => (
                <option
                  id={item.categoryId}
                  value={item.category}
                  key={item.categoryId}
                >
                  {item.category}
                </option>
              ))}
            </Select>
          </>
        ) : null}
      </FormControl>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    categoryData: (data: any) =>
      dispatch(SelectCategoryUseCaseHandler.getCategoryListData(data)),
  };
};

const mapStateToProps = (state: any) => {
  const {
    fetchingCategoryList,
    fetchedCategoryOnChage,
    fetchedCategoryList,
    categories,
  } = state.userCategoryReducer;
  return {
    fetchingCategoryList,
    fetchedCategoryOnChage,
    fetchedCategoryList,
    categories,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCategoryComponent);
