// It will impliment the userListUseCase Interface here..
// export UserListUseCaseHanlder implements UserListUseCase;

import ApiService from "../api/ApiService";

import { SelectCategoryUseCase } from "../adapter/usecase/SelectCategoryUseCase";

class SelectCategoryUseCaseHandler implements SelectCategoryUseCase {
  getCategoryListData = (data) => {
    return (dispatch) => {
      dispatch({ type: "FETCH_CATEGORY_LIST_DATA" });
      ApiService.categoryDataList(data)
        .then((response) => {
          dispatch({
            type: "FETCH_CATEGORY_LIST_DATA_SUCCESS",
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "FETCH_CATEGORY_LIST_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  };
}
export default new SelectCategoryUseCaseHandler();
