import React, { Component } from "react";
import { Grid } from "@material-ui/core/";
import ExistingListTable from "./ExistingListTable";
import TradeImportComponent from "./TradeImportComponent"
class SettlementLIst extends Component<any, any> {
  render() {
    return (
      <Grid className="CoomonT mainCt">
        <div className="innerprofilesection">
          
        <TradeImportComponent />
        <ExistingListTable />
        </div>
      </Grid>
    );
  }
}

export default SettlementLIst;
