import React, { Component } from "react";
import AutoCompleteScrip from "./AutoCompleteScrip";
import SelectCategoryComponent from "./SelectCategoryComponent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormControl, Tooltip } from "@material-ui/core/";
import { Select, Grid } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import ApiService from "../../api/ApiService";
import { connect } from "react-redux";
import UserListUseCaseHandler from "../../usecase/UserListUseCaseHandler";
import CachedIcon from "@material-ui/icons/Cached";
import IconButton from "@material-ui/core/IconButton";
import InfoMessage from "../Form/Message/InfoMessage";
interface Istate {
  userMetaInfo: any;
}
class AddResearchCallComponent extends Component<any, any> {
  state = {
    resetForm: false,
    category: "",
    categoriesId: "",
    symbol: "",
    exchange: "",
    currentMarketPrice: "",
    buySellIndicator: "",
    entryPrice: "",
    targetPrice: "",
    expectedReturnInPercentage: "",
    expectedReturnInDuration: "",
    returnIn: "",
    stopLossPrice: "",
    fairPrice: "",
    scripId: "",
    actions: "",
    userMetaInfo: {
      addCall: "",
      adminRollType: "",
    },
    fairPriceValidationMessage: "",
    expectedReturnInPercentageError: "",
    expectedReturnInDurationError: "",
    showMessage: false,
    currentMarketPriceHelpText: "",
    fetchingLatestCMP: false,
  };

  toggleReset = (value) => {
    this.setState({
      resetForm: value,
    });
  };

  handleKeyUp = (event) => {
    if (
      event.target.value &&
      this.state.currentMarketPrice &&
      this.state.category === "IT Sector Stocks"
    ) {
      if (
        parseInt(event.target.value) > 0 &&
        parseInt(event.target.value) <= parseInt(this.state.currentMarketPrice)
      ) {
        this.setState({
          fairPriceValidationMessage: "It must be greater than CMP",
        });
      } else if (
        parseInt(event.target.value) > parseInt(this.state.currentMarketPrice)
      ) {
        this.setState({
          fairPriceValidationMessage: "",
        });
      }
    }
  };
  handleKeyUpCMP = (event) => {
    if (
      event.target.value &&
      this.state.fairPrice &&
      this.state.category === "IT Sector Stocks"
    ) {
      if (
        parseInt(event.target.value) > 0 &&
        parseInt(event.target.value) < parseInt(this.state.fairPrice)
      ) {
        this.setState({
          fairPriceValidationMessage: "",
        });
      } else if (
        parseInt(event.target.value) >= parseInt(this.state.fairPrice)
      ) {
        this.setState({
          fairPriceValidationMessage: "It must be greater than CMP",
        });
      }
    }
  };

  handleSearchScripName = (scripData) => {
    this.setState({
      symbol: scripData.symbol,
      scripId: scripData.scripId,
      exchange: scripData.exchange,
    });
  };
  handleCmpdData = (data) => {
    this.setState({
      currentMarketPrice: data,
    });
  };
  handleCategoryChange = (data) => {
    this.setState((prevState) => ({
      category: data.value,
      categoriesId: data.id,
      resetForm: false,
      fairPrice: data.value !== "IT Sector Stocks" ? "" : prevState.fairPrice,
      fairPriceValidationMessage:
        data.value !== "IT Sector Stocks"
          ? ""
          : prevState.fairPriceValidationMessage,
    }));
  };
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(e.target.name, e.target.value);

    if (e.target.name === "entryPrice" || e.target.name === "targetPrice") {
      setTimeout(() => {
        if (this.state.entryPrice !== "" && this.state.targetPrice !== "") {
          let targetPrice = parseInt(this.state.targetPrice);
          let entryPrice = parseInt(this.state.entryPrice);
          let expReturninPer = Math.abs(
            ((targetPrice - entryPrice) / entryPrice) * 100
          ).toFixed(2);
          this.setState({
            expectedReturnInPercentage: expReturninPer,
          });
        } else {
          this.setState({
            expectedReturnInPercentage: "",
          });
        }
      }, 2000);
    }
  };

  handleKeyUpTargetPrice = (event) => {
    console.log(event.target.value);
    this.setState({
      targetPrice: event.target.value,
    });
  };

  onHandlerKeyUp = (event) => {
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            this.setState({
              [`${event.target.name}Error`]: "Decimal value not allowed.",
            });
          } else {
            this.setState({
              [`${event.target.name}Error`]: "",
            });
          }
        }
      }
    }
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    //alert(this.state.fairPrice + this.state.category);
    let data = {
      symbol: this.state.symbol,
      scripId: this.state.scripId,
      exchange: this.state.exchange,
      netChangeIndicator: "+",
      changeInPrice: 0,
      changeInPercentage: 0,
      currentMarketPrice: this.state.currentMarketPrice,
      expectedReturnInPercentage: this.state.expectedReturnInPercentage,
      expectedReturnInDuration: this.state.expectedReturnInDuration,
      entryPrice: this.state.entryPrice,
      targetPrice: this.state.targetPrice,
      stopLossPrice: this.state.stopLossPrice,
      fairPrice: this.state.fairPrice,
      categoriesId: this.state.categoriesId,
      image: "",
      isActive: true,
      returnIn: this.state.returnIn,
      actions: "I",
      notificationFlag: true,
      buySellIndicator: this.state.buySellIndicator,
    };

    if (
      (data.fairPrice === "" &&
        this.state.fairPriceValidationMessage === "" &&
        this.state.expectedReturnInPercentageError === "" &&
        this.state.expectedReturnInDurationError === "") ||
      (data.fairPrice !== "" &&
        parseInt(data.fairPrice) > parseInt(data.currentMarketPrice) &&
        this.state.fairPriceValidationMessage === "" &&
        this.state.expectedReturnInPercentageError === "" &&
        this.state.expectedReturnInDurationError === "")
    ) {
      this.addResearchCall(data);
      this.setState({
        showMessage: true,
      });

      setTimeout(() => {
        this.setState({
          showMessage: false,
        });
      }, 5000);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode !== this.props.statusCode) {
      this.setState({
        statusCode: nextProps.statusCode,
      });
      if (nextProps.statusCode !== "" && nextProps.statusCode === 200) {
        this.setState({
          resetForm: true,
          symbol: "",
          scripId: "",
          exchange: "",
          currentMarketPrice: "",
          expectedReturnInPercentage: "",
          expectedReturnInDuration: "",
          entryPrice: "",
          targetPrice: "",
          stopLossPrice: "",
          fairPrice: "",
          categoriesId: "",
          returnIn: "",
          actions: "",
          fairPriceValidationMessage: "",
          expectedReturnInPercentageError: "",
          expectedReturnInDurationError: "",
          buySellIndicator: "",
        });
      }
    }
  }

  addResearchCall = (data: any) => {
    this.props.addResearch(data);
  };

  componentDidMount() {
    let userMetaInfo: any = localStorage.getItem("data");
    let updatedMetaInfo = JSON.parse(userMetaInfo);
    // alert(userMetaInfo);
    this.setState({
      userMetaInfo: updatedMetaInfo,
    });
  }

  getCMPRefreshedData = () => {
    let bowSessionID = localStorage.getItem("bowSessionID");
    if (bowSessionID !== null) {
      bowSessionID = bowSessionID.slice(1, -1);
    }
    let cmpBody = {
      scripTokenNumber: this.state.scripId,
      exchangeId: this.state.exchange === "BSE" ? 1 : 2,
      sessionKey: bowSessionID,
    };
    this.setState({
      fetchingLatestCMP: true,
    });
    ApiService.cmpData(cmpBody).then((res) => {
      if (res.data.statusCode === 200) {
        this.setState({
          currentMarketPrice: res.data.body.MarketDepthData[0].LastTradePrice,
          fetchingLatestCMP: false,
        });
      }
    });
  };

  render() {
    return (
      <Grid>
        <Grid container className="addDatasection">
          <form
            style={formContainer}
            onSubmit={this.handleSubmit}
            id="addResearchCall"
          >
            <div className="formW">
              <SelectCategoryComponent
                change={this.handleCategoryChange}
                resetForm={this.state.resetForm}
                toggleReset={this.toggleReset}
              />
            </div>
            <div className="formW">
              <AutoCompleteScrip
                selectedName={this.handleSearchScripName}
                currentPrice={this.handleCmpdData}
                resetForm={this.state.resetForm}
                toggleReset={this.toggleReset}
              />
            </div>
            <div className="formW">
              <TextField
                label="Exchange"
                fullWidth
                margin="normal"
                name="exchange"
                value={this.state.exchange}
                disabled
              />
            </div>
            <div className="formW">
              <FormControl required fullWidth>
                <InputLabel>B/S Indicator</InputLabel>

                <Select
                  native
                  value={this.state.buySellIndicator}
                  onChange={this.handleChange}
                  name="buySellIndicator"
                >
                  <option aria-label="None" value="" />
                  <option value="B">Buy</option>
                  <option value="S">Sell</option>
                </Select>
              </FormControl>
            </div>
            <div className="formW">
              <TextField
                label="CMP"
                fullWidth
                margin="normal"
                name="currentMarketPrice"
                type="number"
                onChange={this.handleChange}
                value={this.state.currentMarketPrice}
                required
                inputProps={{ min: "1", step: "any" }}
                onKeyUp={this.handleKeyUpCMP}
              />
              {this.state.currentMarketPrice !== "" &&
              this.state.exchange !== "" &&
              this.state.scripId !== "" ? (
                <span
                  className={
                    this.state.fetchingLatestCMP
                      ? "refreshIcon green"
                      : "refreshIcon"
                  }
                  onClick={this.getCMPRefreshedData}
                >
                  <Tooltip title="Refresh CMP">
                    <IconButton aria-label="Refresh CMP">
                      <CachedIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              ) : null}
            </div>

            <div className="formW">
              <TextField
                type="number"
                label=" Entry Price"
                fullWidth
                margin="normal"
                name="entryPrice"
                onChange={this.handleChange}
                value={this.state.entryPrice}
                required
                inputProps={{ min: "1", step: "any" }}
              />
            </div>
            <div className="formW">
              <TextField
                type="number"
                label="Target Price"
                fullWidth
                margin="normal"
                name="targetPrice"
                onChange={this.handleChange}
                value={this.state.targetPrice}
                required
                inputProps={{ min: "1", step: "any" }}
              />
            </div>
            <div className="formW">
              <TextField
                type="number"
                label="Stop Loss"
                fullWidth
                margin="normal"
                name="stopLossPrice"
                onChange={this.handleChange}
                value={this.state.stopLossPrice}
                required
                inputProps={{ min: "1", step: "any" }}
              />
            </div>
            {this.state.category === "IT Sector Stocks" ? (
              <div className="formW">
                <TextField
                  label="Fair Price"
                  fullWidth
                  margin="normal"
                  name="fairPrice"
                  type="number"
                  onChange={this.handleChange}
                  onKeyUp={this.handleKeyUp}
                  value={
                    this.state.category === "IT Sector Stocks"
                      ? this.state.fairPrice
                      : ""
                  }
                  inputProps={{ min: "1", step: "any" }}
                  helperText={this.state.fairPriceValidationMessage}
                  required
                />
              </div>
            ) : null}
            <div className="formW">
              <TextField
                disabled
                type="number"
                label="Exp. Return In %"
                fullWidth
                margin="normal"
                name="expectedReturnInPercentage"
                value={this.state.expectedReturnInPercentage}
                required
                helperText={this.state.expectedReturnInPercentageError}
                inputProps={{ min: "1", step: "any" }}
              />
            </div>
            <div className="formW">
              <TextField
                type="number"
                label="Return Time"
                fullWidth
                margin="normal"
                name="expectedReturnInDuration"
                onChange={this.handleChange}
                value={this.state.expectedReturnInDuration}
                required
                onKeyUp={this.onHandlerKeyUp}
                helperText={this.state.expectedReturnInDurationError}
                inputProps={{ min: "1", step: "any" }}
              />
            </div>
            <div className="formW">
              <FormControl required fullWidth>
                <InputLabel>Return In</InputLabel>
                <Select
                  native
                  value={this.state.returnIn}
                  onChange={this.handleChange}
                  name="returnIn"
                >
                  <option aria-label="None" value="" />
                  <option value="days">Day(s)</option>
                  <option value="weeks">Week(s)</option>
                  <option value="months">Month(s)</option>
                  <option value="years">Year(s)</option>
                </Select>
              </FormControl>
            </div>
            <div className="form-btn">
              {this.state.userMetaInfo &&
              this.state.userMetaInfo.addCall === "Y" ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  value="submit"
                  disableElevation
                >
                  Add Research Call
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  value="submit"
                  disabled
                >
                  Add Research Call
                </Button>
              )}

              {this.props.statusCode !== "" && this.state.showMessage ? (
                this.props.messageInfo && this.props.statusCode === 200 ? (
                  <InfoMessage
                    success={true}
                    message={this.props.messageInfo}
                  />
                ) : (
                  <InfoMessage
                    success={false}
                    message={this.props.messageInfo}
                  />
                )
              ) : null}
            </div>
          </form>
        </Grid>
      </Grid>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

const mapStateToProps = (state: any) => {
  return {
    statusCode: state.userListsReducer.statusCode,
    messageInfo: state.userListsReducer.message,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    userListData: () =>
      dispatch(UserListUseCaseHandler.getReasearchCallsData()),
    addResearch: (data) =>
      dispatch(UserListUseCaseHandler.addResearchCallData(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResearchCallComponent);
