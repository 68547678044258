import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { TableHeader, Pagination, Search } from "../DataTable";
import UserListUseCaseHanlder from "../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../Utils/Loader";
import CloseIcon from "@material-ui/icons/Close";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Publish";
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Tooltip from "@material-ui/core/Tooltip";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TradeImportComponent from "../Ipo/AddLogo";
import RHPImportComponent from "../Ipo/AddRHP";
import FinancialImportComponent from "../Ipo/AddFinancial";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import FaqPage from "../Ipo/faqPageNew";
import NotificationPage from "../Ipo/NotificationPage";
import AutoCompleteScripIpo from "../User/AutoCompleteScripIpo";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {
  Select,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core/";
import ApiService from "../../api/ApiService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@material-ui/core/DialogContentText';

import axios from "axios";

const AgentMaster = (props) => {
  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editaBtnClicked, setEditaBtnClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);
  const [publishModal, setPublishModal] = React.useState(false);
  const [resetForm, setResetform] = useState(false)
  const [exchange_Id, setExchange] = useState("")
  const [scripId, setScripId] = useState("")
  const [defaultData, setDefaultData] = React.useState({
    categoriesName: "",
    categoriesSegmentName: "",
    exchName: "",
    marketName: "",
    buySellInd: "",
  });
  const [notification, setNofication] = React.useState(true);
  const handlePublishChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  const handleModifyChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };
  const handleCloseChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };




  const [
    fairPriceValidationMessage,
    setFairPriceValidationMessage,
  ] = React.useState("");

  const [
    expectedReturnInDurationError,
    setExpectedReturnInDurationError,
  ] = React.useState("");

  const [
    expectedReturnInPercentageError,
    setExpectedReturnInPercentageError,
  ] = React.useState("");


  const [isOpeningDialog, setisOpeningDialog] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);

  const [isOpen, setisOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setisOpen(true);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [isOpenTwo, setisOpenTwo] = React.useState(false);
  const handleDialogOpenTwo = (e, id) => {
    setisOpenTwo(true);
    setRowId(id);
  };


  const [isOpenThree, setisOpenThree] = React.useState(false);
  const handleDialogOpenThree = (e, id) => {
    setisOpenThree(true);
    setRowId(id);
  };



  const handleDialogCloseTwo = () => {
    setisOpenTwo(false);
  };
  const handleDialogCloseThree = () => {
    setisOpenThree(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [editableData, setEditableData] = useState({

    settlementNo: "",
    tradeDate: '',
    settlementDate: '',
    insertedDate: '',
  });




  const [userMetaInfo, setUserMetaInfo] = useState({
    addCall: "",
    adminRollType: "",
    closedCall: "",
    createdAt: "",
    modifyCall: "",
    publishCall: "",
  });


  const [meta, setMeta] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onHandlerKeyUpExp = (event) => {
    // alert("test");
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInPercentageError("Decimal value not allowed");
          } else {
            setExpectedReturnInPercentageError("");
          }
        }
      }
    }
  };

  const onHandlerKeyUpReturnInDuration = (event) => {
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInDurationError("Decimal value not allowed");
          } else {
            setExpectedReturnInDurationError("");
          }
        }
      }
    }
  };




  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getApiDataSettlement());
  }, [message]);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
  }, []);

  const editUser = (data: any) => {
    // alert(JSON.stringify(data));
    setOpen(true);
    setEditaBtnClicked(true);
    setEditableData(data);
  };

  const handleChange = (e: any) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
  };
  // const handleKeyUp = (event) => {
  //   if (event.target.value && editableData.currentMarketPrice) {
  //     if (
  //       parseInt(event.target.value) > 0 &&
  //       parseInt(event.target.value) < parseInt(editableData.currentMarketPrice)
  //     ) {
  //       setFairPriceValidationMessage("It Must be greater than CMP");
  //     } else if (
  //       parseInt(event.target.value) > parseInt(editableData.currentMarketPrice)
  //     ) {
  //       setFairPriceValidationMessage("");
  //     }
  //   }
  // };

  // const handleKeyUpCMP = (event) => {
  //   if (event.target.value && editableData.fairPrice) {
  //     if (
  //       parseInt(event.target.value) > 0 &&
  //       parseInt(event.target.value) < parseInt(editableData.fairPrice)
  //     ) {
  //       setFairPriceValidationMessage("");
  //     } else if (
  //       parseInt(event.target.value) > parseInt(editableData.fairPrice)
  //     ) {
  //       setFairPriceValidationMessage("It Must be greater than CMP");
  //     }
  //   }
  // };



  // const onHandlerKeyUpTargetExpPrice = (e: any) => {
  //   console.log(e.target.name, e.target.value);
  //   if (editableData.entryPrice !== "" && editableData.targetPrice !== "") {
  //     let targetPrice = parseInt(editableData.targetPrice);
  //     let entryPrice = parseInt(editableData.entryPrice);
  //     let expReturninPer = Math.abs(
  //       ((targetPrice - entryPrice) / entryPrice) * 100
  //     ).toFixed(2);
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: expReturninPer,
  //     });
  //   } else if (
  //     editableData.entryPrice === "" &&
  //     editableData.targetPrice === ""
  //   ) {
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: "",
  //     });
  //   }
  // };

  const [StateVariable, setStateVariable] = useState({
    symbol: "",
    scripId: "",
    exchange: ""
  })
  const handleSearchScripName = (scripData) => {
    console.log('scrpt', scripData)
    setExchange(scripData.exchange)
    setScripId(scripData.scripId)
    // setStateVariable({
    //   symbol: scripData.symbol,
    //   scripId: scripData.scripId,
    // });
  };



  const [StateCmpdData, setStateCmpdData] = useState({
    currentMarketPrice: ""
  })
  const handleCmpdData = (data) => {
    setStateCmpdData({
      currentMarketPrice: data,
    });
  };


  const toggleReset = (value) => {
    setResetform(value)
  };








  const [StateCategory, setStateCategory] = useState({
    symbol: "",
    scripId: "",
    exchange: ""
  })
  const handleCategoryChange = (data) => {
    setStateCategory({
      symbol: data.symbol,
      scripId: data.scripId,
      exchange: data.exchange
    });
  };







  const handleSubmit = (e: any) => {
    e.preventDefault();
    // alert(editableData);
    let data = {
      settlementNo: editableData.settlementNo,
      tradeDate: editableData.tradeDate,
      settlementDate: editableData.settlementDate,
      insertedDate: editableData.insertedDate,

     
    };
    console.log(data, "modified data");
    modifyCall(data);
    setEditableData({

      settlementNo: "",
      tradeDate: '',
      settlementDate: '',
      insertedDate: '',
    });
    setNofication(true);

  };


  const modifyCall = (data: any) => {
    dispatch(UserListUseCaseHanlder.modifyIPOCallSettlement(data));
    setOpen(false);
  };
  const addDefaultSrc = (e) => {
    e.target.src = "https://invest19.com/notfound.png";
    e.target.onerror = null;
  };




  const headers = [
    // { name: "ID", field: "id", sortable: true },
    { name: "exchange", field: "exchange", sortable: true },
    { name: "settlementNo", field: "settlementNo", sortable: false },
    { name: "tradeDate", field: "tradeDate", sortable: false },
    { name: "settlementDate", field: "settlementDate", sortable: false },
    { name: "insertedDate", field: "insertedDate", sortable: true },

    // { name: "Close Date", field: "closeDate", sortable: true },
    // { name: "Listing Date", field: "listing_Date", sortable: false },
    // { name: "app. ser. start", field: "application_series_start", sortable: false },
    // { name: "app. ser. end", field: "application_series_end", sortable: false },
    // { name: "fin./RHP", field: "", sortable: false },
    // { name: "status", field: "status", sortable: false },
    // // { name: "Action", field: "", sortable: false },
    // { name: "", field: "", sortable: false },
  ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
            data.symble.toLowerCase().includes(search.toLowerCase()) ||
            data.companyName.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas && computedDatas.length && computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);


  const clickHandle = () => {
    window.location.reload();
    // window.confirm("Hello World!");
    // console.log('You clicked the button')
  }



  //logo

  const onUpload = (url) => {
    setEditableData((oldValue) => {
      return { ...oldValue, logo: url };
    })
  }


  const onUploadrhp = (url) => {
    setEditableData((oldValuerhp) => {
      return { ...oldValuerhp, rhp: url };
    })
  }

  const onUploadFinancialI = (url) => {
    setEditableData((oldValuerhp) => {
      return { ...oldValuerhp, financial_Pdf: url };
    })
  }


  return (

    <>
      <div className="searchUpdatesectin noneS">
        <Search
          onSearch={(value) => {
            setSearch(value);
            setCurrentPage(1);
          }}
        />
      </div>
      <Paper className="tableP">
        <Grid container className="listContainer ipolisting newUiupadte">
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <TableBody>
              {fetchedUserList ? (
                datasData && datasData.map((data: any) => (
                  <TableRow id={data.settlementNo} key={data.settlementNo} className={data.settlementNo}>
                    {/* <TableCell align="left">{data.id}</TableCell> */}
                    <TableCell align="left"> <span className="symbleN">{data.exchange}</span></TableCell>
                    <TableCell align="left"><span className="">{data.settlementNo}</span></TableCell>
                    <TableCell align="left">{data.tradeDate}</TableCell>
                    <TableCell align="left">{data.settlementDate}</TableCell>
                    <TableCell align="left">{data.insertedDate}</TableCell>

                    

                    <TableCell align="left">
                      {/* <IconButton aria-label="delete" size="small">
                        
                        <DeleteRoundedIcon fontSize="small" 
                        
                        onClick={handleDialogOpen}
                        />
                      </IconButton> */}




                      {/* <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setOpen(true);
                            setEditaBtnClicked(true);
                            setEditableData(data);
                          }}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip> */}
                      {/* <Button variant="outlined" color="primary" >
        Open simple dialog
      </Button> */}
                      <Dialog open={isOpen} onClose={handleDialogClose}>
                        {/* <DialogTitle>Confirm</DialogTitle> */}
                        <DialogContent>
                          <DialogContentText>Are you sure?</DialogContentText>
                        </DialogContent>
                        <DialogActions className="basic-popupbtn">

                          <Button onClick={handleDialogClose} color="primary">
                            no
                          </Button>
                          {/* <Button onClick={(event) => { removeData(data.id); handleDialogClose(); clickHandle(); }} color="primary" autoFocus>
                            yes
                          </Button> */}
                        </DialogActions>
                      </Dialog>

                    </TableCell>
                  </TableRow>

                ))

              ) : (

                <Loader />

              )}
            </TableBody>
          </Table>


          <div>

            {/* <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div> */}


            {editaBtnClicked && editableData ? (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <span>Do you want to Modify the IPO {editableData.settlementNo} ? </span>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      <CloseIcon />
                    </Button>
                  </DialogActions>
                </DialogTitle>

                <DialogContent>
                  <Grid container className="addDatasection">

                    <form
                      style={formContainer}
                      onSubmit={handleSubmit}
                      id="modifyipo"
                      autoComplete="off"
                    >
                      <div className="formW intop">
                        <TextField
                          label="tradeDate"
                          type="text"
                          fullWidth
                          margin="normal" onChange={handleChange}
                          name="tradeDate"
                          value={editableData.tradeDate}
                        />
                      </div>
                      <div className="formW intop">
                        <TextField
                          label="settlementDate"
                          fullWidth
                          type="text" onChange={handleChange}
                          margin="normal"
                          name="settlementDate"
                          value={editableData.settlementDate}

                        />
                      </div>


                      <div className="formW intop">
                        <TextField type="text" label="insertedDate" name="insertedDate" onChange={handleChange} value={editableData.insertedDate} />
                      </div>

                      
                      <br />

                      <div style={{ width: "100%" }} className="btnMar">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          value="submit"
                          // onClick={(event) => { clickHandle();}}
                          disableElevation
                        >
                          Yes
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </form>

                  </Grid>
                </DialogContent>
              </Dialog>
            ) : null}
          </div>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

export default AgentMaster;


