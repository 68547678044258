export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const FETCH_USER_LIST_DATA_SUCCESS = "FETCH_USER_LIST_DATA_SUCCESS";
export const FETCH_USER_LIST_DATA_ERROR = "FETCH_USER_LIST_DATA_ERROR";
export const FETCH_USER_LIST_DATA = "FETCH_USER_LIST_DATA";

export const FETCH_POST_LIST = "FETCH_POST_LIST";
export const FETCH_POST_LIST_SUCCESS = "FETCH_POST_LIST_SUCCESS";
export const FETCH_POST_LIST_FAILURE = "FETCH_POST_LIST_FAILURE";

export const POSTING_DATA_SUCCESS = "POSTING_DATA_SUCCESS";
export const POSTING_DATA_FAILURE = "POSTING_DATA_FAILURE";

export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const FETCH_CATEGORY_LIST_DATA = "FETCH_CATEGORY_LIST_DATA";
export const FETCH_CATEGORY_LIST_DATA_SUCCESS =
  "FETCH_CATEGORY_LIST_DATA_SUCCESS";
export const FETCH_CATEGORY_LIST_DATA_ERROR = "FETCH_CATEGORY_LIST_DATA_ERROR";

export const CATEGORY_CHANGE = "CATEGORY_CHANGE";
export const CATEGORY_CHANGE_SUCCESS = "CATEGORY_CHANGE_SUCCESS";
export const CATEGORY_CHANGE_FAILURE = "CATEGORY_CHANGE_FAILURE";

export const MODIFY_USER_DATA = "MODIFY_USER_DATA";
export const MODIFY_USER_DATA_SUCCESS = "MODIFY_USER_DATA_SUCCESS";
export const MODIFY_USER_DATA_ERROR = "MODIFY_USER_DATA_ERROR";


export const MODIFY_IPO_DATA = "MODIFY_IPO_DATA";
export const MODIFY_IPO_DATA_SUCCESS = "MODIFY_IPO_DATA_SUCCESS";
export const MODIFY_IPO_DATA_ERROR = "MODIFY_IPO_DATA_ERROR";


// settlement
export const FETCH_SETTLEMENT_DATA = "FETCH_SETTLEMENT_DATA";
export const FETCH_SETTLEMENT_SUCCESS = "FETCH_SETTLEMENT_SUCCESS";
export const FETCH_SETTLEMENT_ERROR = "FETCH_SETTLEMENT_ERROR";


export const MODIFY_SETTLEMENT_DATA = "MODIFY_SETTLEMENT_DATA";
export const MODIFY_SETTLEMENT_SUCCESS = "MODIFY_SETTLEMENT_SUCCESS";
export const MODIFY_SETTLEMENT_ERROR = "MODIFY_SETTLEMENT_ERROR";
// settlement




export const CLOSE_USER_DATA = "CLOSE_USER_DATA";
export const CLOSE_USER_DATA_SUCCESS = "CLOSE_USER_DATA_SUCCESS";
export const CLOSE_USER_DATA_ERROR = "CLOSE_USER_DATA_ERROR";

export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_USER_DATA_SUCCESS = "ADD_USER_DATA_SUCCESS";
export const ADD_USER_DATA_ERROR = "ADD_USER_DATA_ERROR";

export const PUBLISH_USER_DATA = "PUBLISH_USER_DATA";
export const PUBLISH_USER_DATA_SUCCESS = "PUBLISH_USER_DATA_SUCCESS";
export const PUBLISH_USER_DATA_ERROR = "PUBLISH_USER_DATA_ERROR";

export const FALSE_SHOW_MESSAGE = "FALSE_SHOW_MESSAGE";
export const SHOW_MESSAGE_SUCCESS = "SHOW_MESSAGE_SUCCESS";
